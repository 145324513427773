import React, { createContext, useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useLocalState } from "../App"

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
	const [darkMode, setDarkMode] = useLocalState(false, "darkMode");

	const toggleDarkMode = () => {
		setDarkMode((prevMode) => !prevMode);
	};

	const typography = {
		"fontFamily": `"Poppins", "Helvetica", "Arial", sans-serif`,
		"h1": {
			fontSize: "1.2rem",
			fontWeight: 700,
			textTransform: "uppercase"
		},
		"h3": {
			fontSize: "1rem",
			fontWeight: 500,
			textTransform: "uppercase"
		},
		"h6": {
			fontSize: "0.6rem",
			fontWeight: 100,
			textTransform: "uppercase"
		},
		"subtitle1": {
			fontSize: "1rem",
			fontWeight: 300,
			textTransform: "uppercase"
		},
		"subtitle2": {
			fontSize: "0.8rem",
			fontWeight: 200
		}
	};

	const darkTheme = createTheme({
		typography: typography,
		palette: {
			mode: 'dark',
			leftNav: {
				main: "#000000",
			},
			leftNavText: {
				main: "#ffffff"
			},
			topNav: {
				main: "#ffffff"
			},
			background: {
				default: "#181818",
				main: "rgb(24, 24, 24)"
			}
		},
		components: {
			MuiInputLabel: {
				styleOverrides: {
					root: {
						backgroundColor: '#272B31',
						paddingLeft: '4px',
						paddingRight: '4px'
					}
				}
			},
		}
	});

	const lightTheme = createTheme({
		typography: typography,
		palette: {
			mode: 'light',
			leftNav: {
				main: "#2a2f42",
				contrastText: "#ffffff"
			},
			leftNavText: {
				main: "#ffffff"
			},
			topNav: {
				main: "#ffffff"
			},
			background: {
				default: "#f2f2f5",
				main: "rgb(242, 242, 245)"
			}
		},
		components: {
			MuiInputLabel: {
				styleOverrides: {
					root: {
						backgroundColor: '#ffffff',
						paddingLeft: '4px',
						paddingRight: '4px'
					}
				}
			},//rgb(26, 32, 39)
		}
	});

	return (
		<DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
			<ThemeProvider theme={darkMode ? darkTheme : lightTheme }>
				{children}
			</ThemeProvider>
		</DarkModeContext.Provider>
	);
};

export const useDarkMode = () => {
	return useContext(DarkModeContext);
};
