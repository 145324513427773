import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WalletToggle } from "../shared/WalletToggle";
import { Slippage } from "../shared/Slippage"
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { TabPanel } from '../shared/TabPanel'
import { TokenAmount } from '../shared/TokenAmount'
import { EthAmount } from '../shared/EthAmount'
import { ShortPercent, Loading } from "../shared/FormattingComponents";
import { PoolSelect } from "../shared/PoolSelect";
import { Flashbots } from "../shared/Flashbots";
import { useWallets } from "../WalletContext"
import { useFetchData } from "../FetchDataContext"
import { formatUnits } from 'viem'
import { usePrice } from "../PriceContext";
import { required, requiredValidDecimal } from "../shared/Validation";

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const sanitizeInput = (input) => {
	return input.trim().replace(/[^0-9.e\-]/g, "");
};

export const BuySellWidget = ({
	inputValue,
	activeTab,
	setActiveTab,
	sellAmount,
	setSellAmount
}) => {
	const { fetchData, connection, chain, setLatestOrder } = useFetchData();
	const { loading, wallet1 } = useWallets();
	const { native } = usePrice();

	const [buyMarketOrder, setBuyMarketOrder] = useState(true);
	const [sellOrderType, setSellOrderType] = useState("Market");
	const [buyPriceETH, setBuyPriceETH] = useState("");
	const [sellPriceETH, setSellPriceETH] = useState("");
	const [buyWallet, setBuyWallet] = useState({ id: 1, wallet: null });
	const [sellWallet, setSellWallet] = useState({ id: 1, wallet: null });
	const [buyAmount, setBuyAmount] = useState("");
	const [buySlippage, setBuySlippage] = useState(-1);
	const [sellSlippage, setSellSlippage] = useState(-1);
	const [buyMarketRate, setBuyMarketRate] = useState();
	const [sellMarketRate, setSellMarketRate] = useState();
	const [price, setPrice] = useState(0);
	const [pool, setPool] = useState("");
	const [bribe, setBribe] = useState(chain == "sol" ? 50000 : 10);
	const [useFlashbots, setUseFlashbots] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		const getPrice = async () => {
			try {
				if (!inputValue || !inputValue.address) {
					throw new Error();
				}
				const response = await fetchData(`/api/token/price/${inputValue.address}?pool=${pool}`);
				setPrice(response);
			} catch (err) {
				setPrice(0);
			}
		}

		getPrice();

		const intervalCall = setInterval(() => {
			getPrice();
		}, 15000);

		return () => {
			clearInterval(intervalCall);
		};
	}, [inputValue, pool]);



	// Form Valid
	useEffect(() => {

		let formData = getFormData(activeTab === 0 ? "buy" : "sell");
		let valid = true;

		if (required(formData.address)) {

		} else {
			valid = false;
		}

		if (requiredValidDecimal(formData.tokenAmount)) {

		} else {
			valid = false;
		}

		if (requiredValidDecimal(formData.ethAmount)) {

		} else {
			valid = false;
		}
		setCanSubmit(valid);
	}, [
		inputValue,
		buyPriceETH,
		sellPriceETH,
		buyMarketOrder,
		sellOrderType,
		buySlippage,
		sellSlippage,
		buyAmount,
		sellAmount
	]);


	useEffect(() => {
		if (activeTab === 0 && buyPriceETH) {
			if (buyMarketOrder) {
				let newPrice = buyPriceETH * (1 / price);
				if (isNaN(newPrice) || isFinite(newPrice) == false) {
					setBuyAmount("");
				} else {
					setBuyAmount(newPrice);
				}
				//estimateBuyGas();
			} else {
				setBuyMarketRate(buyPriceETH * (1 / price));
			}
		}
		if (activeTab === 1 && sellAmount) {
			if (sellOrderType === "Market") {
				setSellPriceETH(sellAmount * price);
			} else if (sellOrderType === "Stop") {
				setSellMarketRate(sellAmount * price);
			} else if (sellOrderType === "Limit") {
				setSellMarketRate(sellAmount * price);
			}
		}
	}, [
		activeTab,
		connection,
		fetchData,
		chain,
		inputValue,
		buyMarketOrder,
		buyPriceETH,
		sellOrderType,
		sellAmount,
		price,
		sellPriceETH,
		buyAmount
	]);

	//const estimateBuyGas = async () => {
	//	let formData = {
	//		wallet: buyWallet.id,
	//		ethAmount: buyPriceETH,
	//		address: inputValue.address,
	//		tokenAmount: buyAmount,
	//		slippage: buySlippage,
	//		orderType: buyMarketOrder ? "Market" : "Limit",
	//	};
	//	const response = await fetchData("/api/function/buy/estimate", "post", formData);
	//	setBuyMarketOrderGas(response);
	//}

	const handleTabChange = (event, newValue) => {
		setBuyMarketOrder(newValue === 2);
		setBuyPriceETH("");
		setBuyAmount("");
		setActiveTab(newValue);
	};

	const handleSelectBuyWallet = (id, wallet) => {
		setBuyWallet({ id: id, wallet: wallet });
	};

	const handleSelectSellWallet = (id, wallet) => {
		setSellWallet({ id: id, wallet: wallet });
	};

	const getFormData = (orderType) => {
		return {
			wallet: orderType === "buy" ? buyWallet?.id : sellWallet?.id,
			ethAmount: orderType === "buy" ? buyPriceETH : sellPriceETH,
			address: inputValue?.address,
			tokenAmount: orderType === "buy" ? buyAmount : sellAmount,
			slippage: orderType === "buy" ? buySlippage : sellSlippage,
			orderType: orderType === "buy" ? buyMarketOrder ? "Market" : "Limit" : sellOrderType,
			pool: pool,
			useFlashbots: useFlashbots,
			bribe: useFlashbots || chain === "sol" ? bribe : 0
		};
	}

	const handleOrder = async (orderType) => {
		const formattedOrderType = orderType.charAt(0).toUpperCase() + orderType.slice(1);	
		let apiUrl = `/api/function/${orderType}`;
		let formData = getFormData(orderType);

		try {
			const response = await fetchData(apiUrl, "post", formData);
			setLatestOrder(response);
			showToast(`${formattedOrderType} order submitted successfully!`);
		} catch (error) {
			showToast(
				`Error submitting ${formattedOrderType} order: ${error.message}`,
				"error"
			);
		}
	};

	const handleBuyOrderTypeChange = (e) => {
		setBuyMarketOrder(e.target.value === "market");
		setBuyPriceETH("");
		setBuyAmount("");
	};

	const handleSellOrderTypeChange = (e) => {
		setSellOrderType(e.target.value);
		setSellPriceETH("");
		setSellAmount("");
	};

	const handleBuyPriceChange = (e) => {
		const sanitizedValue = sanitizeInput(e.target.value);
		setBuyPriceETH(sanitizedValue);
	};

	const handleSellPriceChange = (e) => {
		const sanitizedValue = sanitizeInput(e.target.value);
		setSellPriceETH(sanitizedValue);
	};

	const handleSellStopPriceChange = (e) => {
		const sanitizedValue = sanitizeInput(e.toString());
		setSellPriceETH(sanitizedValue);
	};

	const handleBuyAmountChange = (e) => {
		const sanitizedValue = sanitizeInput(e.target.value);
		setBuyAmount(sanitizedValue);
	};

	const handleSellAmountChange = (e) => {
		const sanitizedValue = sanitizeInput(e.target.value);
		setSellAmount(sanitizedValue);
	};

	const handleBuyOrder = () => {
		handleOrder("buy");
	};

	const handleSellOrder = () => {
		handleOrder("sell");
	};

	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	const getTokenBalance = (wallet, address) => {
		let token = wallet?.tokenBalance?.find(x => x.address?.toLowerCase() == address?.toLowerCase());
		return formatUnits(token?.balance ?? 0, token?.decimals ?? 18);
	}

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
						<Tab label="Buy" {...a11yProps(0)} />
						<Tab label="Sell" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<TabPanel value={activeTab} index={0} className="buySellTabPanel">
					<Stack spacing={2} width="100%" alignItems="stretch">
						<Select
							value={buyMarketOrder ? "market" : "limit"}
							onChange={handleBuyOrderTypeChange}
							fullWidth={true}
							size="small"
						>
							<MenuItem value={"market"}>Market</MenuItem>
							<MenuItem value={"limit"}>Limit</MenuItem>
						</Select>
						<WalletToggle value={buyWallet.id} onChange={handleSelectBuyWallet} allowAll={false} />
						<PoolSelect token={inputValue} setPool={setPool} pool={pool} />
						{buyMarketOrder ? (
							<>
								<TokenAmount label="You Pay" onChange={handleBuyPriceChange} value={buyPriceETH} setValue={setBuyPriceETH} symbol={native.symbol} max={formatUnits((buyWallet.wallet ?? wallet1)?.nativeBalance ?? 0, native.decimals)} />
								<EthAmount value={price} direction="row" title="Current Market Rate:" compact={true} />
								<TextField label="You Receive" variant="outlined" size="small" margin="dense" fullWidth value={buyAmount} InputProps={{
									endAdornment: <InputAdornment position="end">{inputValue?.symbol}</InputAdornment>,
									readOnly: true,
								}} InputLabelProps={{
									shrink: true,
								}} />
							</>
						) : (
							<>
								<TokenAmount label="You Pay" onChange={handleBuyPriceChange} value={buyPriceETH} setValue={setBuyPriceETH} symbol={native.symbol} max={formatUnits((buyWallet.wallet ?? wallet1)?.nativeBalance ?? 0, native.decimals)} />
								<EthAmount value={price} direction="row" title="Current Market Rate:" compact={true} />
								<EthAmount value={buyPriceETH / buyAmount} direction="row" title="Buy When Rate Below:" compact={true} />
								<TextField label="Minimum To Buy" variant="outlined" size="small" margin="dense" fullWidth onChange={handleBuyAmountChange} value={buyAmount} InputProps={{
									endAdornment: <InputAdornment position="end">{inputValue?.symbol}</InputAdornment>
								}} />
								<Box>Versus Market Rate: <ShortPercent value={100 - (buyMarketRate / buyAmount * 100)} /></Box>
							</>
						)}
						<Box sx={{ padding: "0 20px" }}>
							<Slippage value={buySlippage} onChange={setBuySlippage} />
						</Box>
						<Flashbots useFlashbots={useFlashbots} setUseFlashbots={setUseFlashbots} bribe={bribe} setBribe={setBribe} />
						<Button
							variant="contained"
							disabled={canSubmit == false}
							onClick={handleBuyOrder}
						>
							Create Buy Order
						</Button>
					</Stack>
				</TabPanel>
				<TabPanel value={activeTab} index={1} className="buySellTabPanel">
					<Stack spacing={2} width="100%" alignItems="stretch">
						<Select
							value={sellOrderType}
							onChange={handleSellOrderTypeChange}
							fullWidth={true}
							size="small"
						>
							<MenuItem value="Market">Market</MenuItem>
							<MenuItem value="Limit">Take Profit</MenuItem>
							<MenuItem value="Stop">Stop Loss</MenuItem>
						</Select>
						<WalletToggle value={sellWallet.id} onChange={handleSelectSellWallet} allowAll={false} />
						<PoolSelect token={inputValue} setPool={setPool} pool={pool} />
						{sellOrderType === "Market" ? (
							<>
								<TokenAmount label="You Pay" onChange={handleSellAmountChange} value={sellAmount} showPercentageSlider={true} setValue={setSellAmount} symbol={inputValue?.symbol} max={getTokenBalance(sellWallet.wallet ?? wallet1, inputValue?.address)} />
								<EthAmount value={price} direction="row" title="Current Market Rate:" compact={true} />
								<TokenAmount label="You Receive" value={sellPriceETH} symbol={native.symbol} readOnly />

							</>
						) : sellOrderType === "Stop" ? (
							<>
								<TokenAmount label="Amount to Sell" onChange={handleSellAmountChange} value={sellAmount} showPercentageSlider={true} setValue={setSellAmount} symbol={inputValue?.symbol} max={getTokenBalance(sellWallet.wallet ?? wallet1, inputValue?.address)} />
								<EthAmount value={price} direction="row" title="Current Market Rate:" compact={true} />
								<EthAmount value={sellPriceETH / sellAmount} direction="row" title="Stop Loss:" compact={true} />
								<TokenAmount label="Sell If Value Drops Below" variant="outlined" size="small" margin="dense" showPercentageSlider={true} showTextInput={false} showBalance={false} setValue={handleSellStopPriceChange} symbol={native.symbol} value={sellPriceETH} max={sellMarketRate} />
								<Box>Versus Market Rate: <ShortPercent value={(sellPriceETH / sellMarketRate * 100) - 100} /></Box>
							</>
						) : sellOrderType === "Limit" ? (
							<>
								<TokenAmount label="You Pay" onChange={handleSellAmountChange} value={sellAmount} showPercentageSlider={true} setValue={setSellAmount} symbol={inputValue?.symbol} max={getTokenBalance(sellWallet.wallet ?? wallet1, inputValue?.address)} />
								<EthAmount value={price} direction="row" title="Current Market Rate:" compact={true} />
								<EthAmount value={sellPriceETH / sellAmount} direction="row" title="Minimum Sell Rate:" compact={true} />
								<TokenAmount label="Minimum To Receive" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSellPriceChange} value={sellPriceETH} symbol={native.symbol} />
								<Box>Versus Market Rate: <ShortPercent value={(sellPriceETH / sellMarketRate * 100) - 100} /></Box>
							</>
						) : <></>}
						<Box sx={{ padding: "0 20px" }}>
							<Slippage value={sellSlippage} onChange={setSellSlippage} />
						</Box>
						<Flashbots useFlashbots={useFlashbots} setUseFlashbots={setUseFlashbots} bribe={bribe} setBribe={setBribe} />
						<Button
							variant="contained"
							onClick={handleSellOrder}
							disabled={canSubmit == false}
						>
							Create Sell Order
						</Button>
					</Stack>
				</TabPanel>
			</Box>
		</>
	);
};