import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import { TabPanel } from '../shared/TabPanel'
import { StyledTableCell } from '../shared/StyledTable'

export const HotPairs = ({ dashboardData }) => {
	const [activeTab, setActiveTab] = React.useState(0);
	const hotPairsData = dashboardData && dashboardData.hotPairs ? dashboardData.hotPairs : [];

	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	return (
		<>
			<Typography variant="h3">Hot Pairs</Typography>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={activeTab} onChange={handleTabChange}>
						<Tab label="24h" {...a11yProps(0)} />
						<Tab label="7d" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<TabPanel value={activeTab} index={0}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell>#</StyledTableCell>
									<StyledTableCell align="center">Pair</StyledTableCell>
									<StyledTableCell align="center">Trade/Profit</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{hotPairsData.map((row) => (
									<TableRow
										key={row.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row"></TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="center"></TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{hotPairsData.length > 0 ? (
						<></>
					) : (
						<p align="center">No hot trade data available</p>
					)}
				</TabPanel>
				<TabPanel value={activeTab} index={1}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell>#</StyledTableCell>
									<StyledTableCell align="center">Pair</StyledTableCell>
									<StyledTableCell align="center">Trade/Profit</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{hotPairsData.map((row) => (
									<TableRow
										key={row.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row"></TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="center"></TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{hotPairsData.length > 0 ? (
						<></>
					) : (
						<p align="center">No hot trade data available</p>
					)}
				</TabPanel>
			</Box>
		</>
	);
};
