import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useDarkMode } from './DarkModeContext';
import { ReactComponent as LogoLight } from '../img/unodex-logo.svg';
import { ReactComponent as LogoDark } from '../img/unodex-logo-dark.svg';


export const Home = () => {
	const { darkMode } = useDarkMode();

	return (
		<Stack sx={{ width: "100%", marginTop: { xs: 1, md: "20px" } }} justifyContent="center" alignItems="center" spacing={2}>
			{darkMode ?
				<LogoDark width="600" height="300" style={{ maxWidth: "60vw" }}></LogoDark> :
				<LogoLight width="600" height="300" style={{ maxWidth: "60vw" }}></LogoLight>
			}
			<Typography textAlign="center">In order to use this site, please connect to your wallet using the button at the top of the page.</Typography>
		</Stack>
	);
};
