import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { CopyToClipboard } from '../shared/CopyToClipboard';
import { useFetchData } from "../FetchDataContext";

export const PkDialog = ({ walletToShow, setWalletToShow }) => {
	const { fetchData } = useFetchData();

	const [open, setOpen] = React.useState(false);
	const [pk, setPk] = React.useState(null);

	const getPk = async () => {
		const key = await fetchData(`/api/wallet/key/${walletToShow}`);
		setPk(key);
	}

	React.useEffect(() => {
		if (walletToShow >= 0) {
			setOpen(true);
		} else {
			setOpen(false);
		}
		setPk(null);
	}, [walletToShow]);


	const handleClose = () => {
		setWalletToShow(-1);
	};

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={handleClose}
			>
				<DialogTitle>
					{"Export Key"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{pk ?
							<Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%"}}>
								<CopyToClipboard text={pk} />{`${pk.substring(0, 10)}...${pk.substring(pk.length - 10) }`}
							</Stack> :
							<Button onClick={getPk}>Click here to reveal the wallets key.</Button>}	
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}