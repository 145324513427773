import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { ClaimRewardsTile } from "./referrals/ClaimRewardsTile";
import { ReferralLink } from "./referrals/ReferralLink";
import { ReferralRewardHistory } from "./referrals/ReferralRewardHistory";
import { TotalReferrals } from "./referrals/TotalReferrals";
import { TotalRewardsTile } from "./referrals/TotalRewardsTile";
import { TransactionsTile } from "./referrals/TransactionsTile";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { PageSection } from './shared/PageSection';
import { useFetchData } from "./FetchDataContext";
import { PageHeader } from "./shared/FormattingComponents";

export const Referrals = () => {
	const { referralData } = useFetchData();


	if (referralData.loading) {
		return <div>Loading...</div>;
	}
	if (referralData.error) {
		return <div>Error: {referralData.error.message}</div>;
	}

	const {
		referrerTransactions,
		totalReferrals,
		unclaimedRewards,
		totalRewards,
		rewardHistory,
	} = referralData.data;

	return (
		<Box sx={{ flexGrow: 1 }}>
			<PageHeader pageName="Referral Rewards" helpUrl="https://unodex.gitbook.io/docs/unodex-trading-app/referrals" />
			<Grid container spacing="20px">
				<Grid item xs={12}>
					<PageSection>
						<ReferralLink referralData={referralData} />
					</PageSection>
				</Grid>
				<Grid item xs={6} md={3} lg={3}>
					<PageSection sx={{ minHeight: "150px"}}>
						<TotalReferrals totalReferrals={totalReferrals} />
					</PageSection>
				</Grid>
				<Grid item xs={6} md={3} lg={3}>
					<PageSection sx={{ minHeight: "150px" }}>
						<TransactionsTile referrerTransactions={referrerTransactions} />
					</PageSection>
				</Grid>
				<Grid item xs={6} md={3} lg={3}>
					<PageSection sx={{ minHeight: "150px" }}>
						<TotalRewardsTile totalRewards={totalRewards} />
					</PageSection>
				</Grid>
				<Grid item xs={6} md={3} lg={3}>
					<PageSection sx={{ minHeight: "150px" }}>
						<ClaimRewardsTile unclaimedRewards={unclaimedRewards} />
					</PageSection>
				</Grid>
				<Grid item xs={12}>
					<PageSection>
						<ReferralRewardHistory rewardHistory={rewardHistory} />
					</PageSection>
				</Grid>
			</Grid>
		</Box>
	);
};
