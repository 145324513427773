import React, { Component } from 'react';
import { CopyTradesCard } from './copyTrades/copyTradesCard';
import { ComingSoon, PageHeader } from './shared/FormattingComponents';
import Typography from '@mui/material/Typography';

export class CopyTrades extends Component {
	static displayName = CopyTrades.name;

	render() {
		return (
			<>
				
				<div style={{ position: "relative" }}>
					<ComingSoon />
					<PageHeader pageName="Copy Trades" helpUrl="https://unodex.gitbook.io/docs/unodex-trading-app/copy-trades" />
					<div>
						<CopyTradesCard />
						<CopyTradesCard />
						<CopyTradesCard />
					</div>
					<div>
						<CopyTradesCard />
						<CopyTradesCard />
						<CopyTradesCard />
					</div>

				</div>
			</>
		);
	}
}
