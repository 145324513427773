import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { DarkModeProvider } from './components/DarkModeContext';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import { WagmiConfig } from 'wagmi'
import { mainnet } from 'viem/chains'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const projectId = "e73dfada4c9b60de5259f8fdf7218a1c";
const metadata = {
	name: 'Unodex',
	description: 'Connect Wallet',
	url: 'https://app.unodex.fi',
	icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
createWeb3Modal({
	wagmiConfig,
	projectId,
	chains,
	themeVariables: {
		'--w3m-z-index': 10000
	},
	defaultChain: mainnet
})

root.render(
	<WagmiConfig config={wagmiConfig}>
		<DarkModeProvider>
			<BrowserRouter basename={baseUrl}>
				<App />
			</BrowserRouter>
		</DarkModeProvider>
	</WagmiConfig>
);