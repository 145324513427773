import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell } from '../shared/StyledTable'
import { ShortDecimal, DateTime, Token, Loading, Status } from '../shared/FormattingComponents'
import { DeleteFunction } from "../shared/DeleteFunction";
import DeleteIcon from '@mui/icons-material/Delete';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FunctionLogs } from '../shared/FunctionLogs'
import Typography from '@mui/material/Typography';
import { useFetchData } from "../FetchDataContext";

export const SniperHistory = () => {
	const { orderData } = useFetchData();
	const [idToDelete, setIdToDelete] = React.useState(false);
	const [idToShow, setIdToShow] = React.useState(false);
	const [sniperData, setSniperData] = React.useState([]);

	React.useEffect(() => {
		setSniperData(orderData != null && orderData.data.length > 0 ? orderData.data.filter((x) => { return x.functionName.toLowerCase() === "launch sniper" && !x.deleted }) : []);
	}, [orderData]);

	return (
		<>
			<FunctionLogs idToShow={idToShow} setIdToShow={setIdToShow} />
			<DeleteFunction idToDelete={idToDelete} setIdToDelete={setIdToDelete} />
			<Typography variant="h3">Sniper History</Typography>
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							<StyledTableCell>Date</StyledTableCell>
							<StyledTableCell align="center">Token</StyledTableCell>
							<StyledTableCell align="center">Status</StyledTableCell>
							<StyledTableCell align="right">Performance</StyledTableCell>
							<StyledTableCell align="center">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sniperData.map((row) => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row"><DateTime value={row.dateModified} /></TableCell>
								<TableCell align="center"><Token value={row.token} /></TableCell>
								<TableCell align="center"><Status value={row.status} /></TableCell>
								<TableCell align="right"><ShortDecimal value={row.performance} /></TableCell>
								<TableCell align="center">
									{row.dateFulfilled ? <></> : <IconButton onClick={() => setIdToDelete(row.id)}><DeleteIcon /></IconButton>}
									<Tooltip title="Logs">
										<IconButton size="small" onClick={() => setIdToShow(row.id)}>
											<ManageSearchIcon />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{orderData.loading ? (<Loading />): sniperData.length === 0 ? <>No sniper history available</> : (<></>) }
		</>
	);
};
