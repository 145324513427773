import React, { useState } from "react";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { toast } from "react-toastify";
import { useFetchData } from "../FetchDataContext";

export const CreateWallet = ({ walletCount }) => {

	const { fetchData } = useFetchData();

	const [isCreating, setIsCreating] = useState(false);
	const maxWallets = 5; // Including the main wallet

	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	const handleCreateWallet = async () => {

		try {
			setIsCreating(true);

			if (walletCount > maxWallets) {
				throw new Error(
					`You have reached the maximum number of wallets (${maxWallets}).`
				);
			}
			await fetchData("/api/wallet", "post");
		} catch (err) {
			showToast(
				`Error creating wallet: ${err.message}`,
				"error"
			);

		} finally {
			setIsCreating(false);
		}
	};

	const isDisabled = isCreating || walletCount >= maxWallets;

	const buttonText = walletCount >= maxWallets
			? "Max Number of Wallets Created"
			: "Create New Wallet";

	return (
		<Box sx={{
			width: "100%", height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
}}>
			<Button
				onClick={handleCreateWallet}
				disabled={isDisabled} variant="contained">
				{buttonText}
			</Button>
		</Box>
	);
};
