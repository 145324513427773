import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PageSection } from './shared/PageSection'
import "react-toastify/dist/ReactToastify.css";
import { useFetchData } from "./FetchDataContext"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell } from './shared/StyledTable'
import { NewPools } from "./shared/NewPools";
import { TokenAmount } from "./shared/TokenAmount";
import { Slippage } from "./shared/Slippage";
import { Flashbots } from "./shared/Flashbots";
import { WalletToggle } from "./shared/WalletToggle";
import { Loading, PageHeader } from "./shared/FormattingComponents"
import { usePrice } from "./PriceContext";
import { useWallets } from "./WalletContext";
import { formatUnits } from 'viem'
import { toast } from "react-toastify";
import { requiredValidDecimal } from "./shared/Validation";
import { EthAmount } from "./shared/EthAmount";


export const Shotgun = () => {
	const { fetchData, chain } = useFetchData();
	const { native } = usePrice();
	const { wallet1 } = useWallets();

	const [running, setRunning] = React.useState(false);
	const [walletIndex, setWalletIndex] = React.useState(1);
	const [wallet, setWallet] = React.useState();
	const [spendPerBuy, setSpendPerBuy] = useState("");
	const [autoSell, setAutoSell] = useState(false);
	const [score, setScore] = useState(40);
	const [autoSellProfit, setAutoSellProfit] = useState(100);
	const [autoSellAmount, setAutoSellAmount] = useState(100);
	const [slippage, setSlippage] = React.useState(25);
	const [bribe, setBribe] = React.useState(chain == "sol" ? 30000 : 10);
	const [timeout, setTimeout] = React.useState(60);
	const [useFlashbots, setUseFlashbots] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [canSubmit, setCanSubmit] = useState(false);
	const [autoBuyMin, setAutoBuyMin] = useState(0); 

	useEffect(() => {
		let valid = true;

		if (requiredValidDecimal(spendPerBuy)) {

		} else {
			valid = false;
		}

		setCanSubmit(valid);
	}, [
		spendPerBuy
	]);

	useEffect(() => {
		setAutoBuyMin(parseFloat(spendPerBuy) + (parseFloat(spendPerBuy) * (parseFloat(autoSellProfit) / 100)))
	}, [
		spendPerBuy, autoSellProfit, autoSellAmount
	]);

	useEffect(() => {
		const getShotgun = async () => {
			try {
				const data = await fetchData("/api/function/shotgun")
				setRunning(data.running);
				console.log(data)
				if (data.function) {
					setWalletIndex(data.function.wallet - 1);
					setScore(data.function.score);
					setSpendPerBuy(data.function.spendPerBuy);
					setAutoSell(data.function.autoSell);
					setAutoSellProfit(data.function.autoSellProfit);
					setAutoSellAmount(data.function.autoSellAmount);
					setSlippage(data.function.slippage);
					setBribe(data.function.bribe)
				}
				setLoading(false);
			}
			catch (err) {
				console.log(err);
				setLoading(false);
			}
		};

		if (loading) {
			getShotgun();
		}
	}, [loading]);

	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	const handleSetWallet = (value, current) => {
		setWalletIndex(value);
		setWallet(current);
	}

	const handleBuyPriceChange = (e) => {
		const sanitizedValue = sanitizeInput(e.target.value);
		setSpendPerBuy(sanitizedValue);
	};

	const handleSetAutoSellAmount = (e, value) => { setAutoSellAmount(sanitizeInput(e.target.value)); }
	const handleSetAutoSellProfit = (e, value) => { setAutoSellProfit(sanitizeInput(e.target.value)); }
	const handleSetTimeout = (e, value) => { setTimeout(sanitizeInput(e.target.value)); }
	const handleSetAutoSell = (e, value) => { setAutoSell(value); }
	const handleScoreChange = (event, value) => { setScore(value); }

	const sanitizeInput = (input) => {
		return input.trim().replace(/[^0-9.e\-]/g, "");
	};

	const handleShotgunStart = async () => {
		try {
			const formData = {
				wallet: walletIndex,
				spendPerBuy,
				autoSell,
				score,
				autoSellProfit,
				autoSellAmount,
				slippage,
				bribe,
				timeout
			};
			console.log(formData)
			await fetchData("/api/function/shotgun", "post", formData);
			setRunning(true);
			showToast(`Shotgun started successfully!`);
		} catch (err) {
			showToast(
				`Error starting Shotgun: ${err.message}`,
				"error"
			);
		}
	};

	const handleShotgunStop = async () => {
		try {
			await fetchData("/api/function/shotgun", "delete");
			setRunning(false);
			showToast(`Shotgun stopped successfully!`);
		} catch (err) {
			showToast(
				`Error stopping Shotgun: ${err.message}`,
				"error"
			);
		}
	};

	const scoreColor = () => {
		if (score <= 5) {
			return "success";
		}
		if (score <= 15) {
			return "warning";
		}

		return "error";
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<PageHeader pageName="Shotgun" helpUrl="https://unodex.gitbook.io/docs/shotgun/presentation" />
			<Grid container spacing={2}>
				<Grid item xs={12} md={12} lg={6}>
					<PageSection>
						{loading ? <Loading /> : running ? (
							<>
								<Button onClick={handleShotgunStop} variant="contained" fullWidth>
									Stop Hunting
								</Button>
								<TableContainer>
									<Table size="small">
										<TableHead>
											<TableRow>
												<StyledTableCell align="center"></StyledTableCell>
												<StyledTableCell align="center"></StyledTableCell>
											</TableRow>
										</TableHead>
										<TableBody>					
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="center">Wallet</TableCell>
												<TableCell align="center">{walletIndex ?? "0"}</TableCell>
											</TableRow>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="center">Spend Per Buy</TableCell>
												<TableCell align="center">{spendPerBuy} {native.symbol}</TableCell>
											</TableRow>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="center">Buy When Score Less Than</TableCell>
												<TableCell align="center">{score ?? "0"}</TableCell>
											</TableRow>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="center">Priority Fee</TableCell>
												<TableCell align="center">{bribe ?? "0"} {native.gasUnit}</TableCell>
											</TableRow>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="center">Create Sell Order</TableCell>
												<TableCell align="center">{autoSell ? "Yes" : "No"}</TableCell>
											</TableRow>
											{autoSell ?
												<>
													<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
														<TableCell align="center">Take Profit</TableCell>
														<TableCell align="center">{autoSellProfit}%</TableCell>
													</TableRow>
													<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
														<TableCell align="center">Percent to sell</TableCell>
														<TableCell align="center">{autoSellAmount}%</TableCell>
													</TableRow>
												</> : 
												<></>}
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="center">Slippage</TableCell>
												<TableCell align="center">{slippage}%</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</>

							) : (
							<Stack spacing={2}>
								<WalletToggle value={walletIndex} onChange={handleSetWallet} allowAll={false} />
								<TokenAmount label="Spend per buy" onChange={handleBuyPriceChange} value={spendPerBuy} setValue={setSpendPerBuy} symbol={native.symbol} max={formatUnits((wallet ?? wallet1)?.nativeBalance ?? 0, native.decimals)} />

								If Score is less than:
								<Box sx={{ padding: "0 20px" }}>
								<Slider
										value={score}
										step={5}
										marks
										min={5}
										max={80}
										color={scoreColor()}
										valueLabelDisplay="auto"
										onChange={handleScoreChange}
									/>
								</Box>

								<FormControlLabel control={<Switch checked={autoSell} onChange={handleSetAutoSell} />} label="Automatically create Take Profit Order" />
								{autoSell ?
									<Stack direction="row" spacing={2}>
										<Box >
											<TextField label="Sell at % Profit" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSetAutoSellProfit} value={autoSellProfit} />
											<EthAmount value={autoBuyMin} />
										</Box>
										<TextField label="Amount to sell" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSetAutoSellAmount} value={autoSellAmount} />
									</Stack> :
									<></>
								}

								<Box sx={{ padding: "0 20px" }}>
									<Slippage value={slippage} onChange={setSlippage}></Slippage>
								</Box>

								<Flashbots useFlashbots={useFlashbots} setUseFlashbots={setUseFlashbots} bribe={bribe} setBribe={setBribe} />

								<TextField label="Stop After (Minutes)" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSetTimeout} value={timeout} />

								<Button onClick={handleShotgunStart} disabled={canSubmit == false} variant="contained" fullWidth>
									Start Hunting
								</Button>
							</Stack>
						)}
					</PageSection>
				</Grid>
				<Grid item xs={12} md={12} lg={6}>
					<PageSection>
						<NewPools maxScore={score} />
					</PageSection>
				</Grid>
			</Grid>
		</Box>

	)
};
