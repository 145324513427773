import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { PageSection } from './shared/PageSection'
import { SniperHistory } from "./snipe/SniperHistory";
import { SniperWidget } from "./snipe/SniperWidget";
import { TokenInfo } from "./snipe/TokenInfo";
import { TokenLookup } from "./shared/TokenLookup";
import Typography from '@mui/material/Typography';
import { PageHeader } from "./shared/FormattingComponents"
import "react-toastify/dist/ReactToastify.css";

export const Snipe = ({ tokenList, selectedToken }) => {
	const [tokenInfo, setTokenInfo] = useState(selectedToken);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<PageHeader pageName="Snipe" helpUrl="https://unodex.gitbook.io/docs/unodex-trading-app/snipe" />
			<Grid container spacing={2}>
				<Grid item xs={12} md={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<PageSection sx={{ minHeight: 0 }}>	
								<TokenLookup tokenList={tokenList} onSelect={setTokenInfo}></TokenLookup>
							</PageSection>
						</Grid>
						<Grid item xs={12}>
							<PageSection>
								<TokenInfo tokenInfo={tokenInfo} />
							</PageSection>
						</Grid>
						<Grid item xs={12}>
							<PageSection>
								<SniperWidget tokenInfo={tokenInfo} />
							</PageSection>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={12} lg={6}>
					<PageSection>
						<SniperHistory />
					</PageSection>
				</Grid>
			</Grid>
		</Box>

	)
};
