import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { StyledTableCell } from '../shared/StyledTable'
import { useContractWrite, } from 'wagmi'
import { toast } from "react-toastify";
import { ShortDecimal, Loading } from "../shared/FormattingComponents";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { EthAmount } from "../shared/EthAmount";
import { useFetchData } from "../FetchDataContext";

export const ClaimBar = () => {
	const { fetchData, rewardsData } = useFetchData();

	const { data, isLoading, isSuccess, write } = useContractWrite({
		address: '0xC0244D0c80D883fC4ef4ee896720aE2642fA011a',
		abi: [{ "inputs": [{ "internalType": "uint256", "name": "claimEpoch", "type": "uint256" }, { "internalType": "uint256", "name": "claimAmount", "type": "uint256" }, { "internalType": "bytes", "name": "signature", "type": "bytes" }], "name": "claim", "outputs": [], "stateMutability": "nonpayable", "type": "function" }],
		functionName: 'claim',
		chainId: 1,
		onSuccess(data) {
			fetchData("/api/user/rewards", "post");
			showToast(`Rewards claimed successfully!`);
		}
	});

	const claimReward = async (index) => {
		try {
			if (index < 0) {
				const d = await fetchData(`/api/user/claimreward/${index}`, "post");
				write?.({ args: [d.lastEpoch, d.owed, d.signature] });
			} else {
				await fetchData(`/api/user/claimreward/${index}`, "post");
			}
		} catch (err) {
			showToast(
				`Error claiming rewards: ${err.response.data?.detail}`,
				"error"
			);
		}
	}

	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	return (
		<>
			<Typography variant="h3">Token Holdings</Typography>
			{rewardsData.loading || rewardsData.error ? <Loading /> :
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								<StyledTableCell align="center">Wallet</StyledTableCell>
								<StyledTableCell align="right" sx={{ display: { xs: "none", sm: "block" } }}>UNDX Holdings</StyledTableCell>
								<StyledTableCell align="right">Claimable ETH</StyledTableCell>
								<StyledTableCell align="center">Actions</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow
								key={-1}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">Linked Wallet</TableCell>
								<TableCell align="right" sx={{ display: { xs: "none", sm: "table-cell" } }}><ShortDecimal value={rewardsData.data.linkedWallet.tokenBalance} /></TableCell>
								<TableCell align="right"><EthAmount value={rewardsData.data.linkedWallet.rewardsOwed} /></TableCell>
								<TableCell align="center">
									<Tooltip title="Claim">
										<IconButton disabled={!write || rewardsData.data.linkedWallet.rewardsOwed === 0} size="small" onClick={() => claimReward(-1)}>
											<MonetizationOnIcon />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
							{rewardsData.data.custodialWallets.map((x, index) => (
								<TableRow
									key={index}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row">{index === 0 ? `Main Wallet` : `Wallet ${index + 1}`}</TableCell>
									<TableCell align="right" sx={{ display: { xs: "none", sm: "table-cell" } }}><ShortDecimal value={x.tokenBalance} /></TableCell>
									<TableCell align="right"><EthAmount value={x.rewardsOwed} /></TableCell>
									<TableCell align="center">
										<Tooltip title="Claim">
											<IconButton size="small" disabled={x.rewardsOwed === 0} onClick={() => claimReward(index)}>
												<MonetizationOnIcon />
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			}
		</>
	)
};
