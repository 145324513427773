import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export const TokenInfo = ({ tokenInfo }) => {
	return (
		<>
			<Typography variant="h3">Token Information</Typography>
			<TableContainer>
				<Table size="small">
					<TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell align="left">
								Address:
							</TableCell>
							<TableCell align="right">
								<p>{tokenInfo?.address}</p>
							</TableCell>
						</TableRow>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell align="left">
								Name:
							</TableCell>
							<TableCell align="right">
								<p>{tokenInfo?.name}</p>
							</TableCell>
						</TableRow>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell align="left">
								Symbol:
							</TableCell>
							<TableCell align="right">
								<p>{tokenInfo?.symbol}</p>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
