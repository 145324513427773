import * as React from 'react';
import axios from "axios";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import debounce from "lodash/debounce";
import { ShortAddress, ShortPercent, ShortDecimal, TokenLogo } from "./FormattingComponents"
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useFetchData } from "../FetchDataContext"
import { EthAmount } from './EthAmount';

const filterOptions = createFilterOptions({
	stringify: (option) => `${option.symbol} ${option.address}`,
	limit: 60
});

export const TokenLookup = ({ value, tokenList, onSelect }) => {
	const { fetchData, chain } = useFetchData();
	const [options, setOptions] = React.useState([]);
	const [filteredOptions, setFilteredOptions] = React.useState([]);
	const [inputValue, setInputValue] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const getTokenList = async (inputValue, options) => {
		const items = [...options];
		var address = inputValue.match(/0x[0-9a-f]{40}/i);
		if (address && address.length > 0) address = address[0];
		if (address || inputValue.length === 42) {			
			if (items.some(x => x.address.toUpperCase() === (address ?? inputValue).toUpperCase()) == false) {
				const response = await fetchData(`/api/token?address=${address ?? inputValue}`);
				items.push(response);
			}
		} else if (inputValue.length > 0) {
			const response = await fetchData(`/api/token/symbol?symbol=${inputValue.replace(/\s?0x[0-9a-f]{40}/gi, "")}`);
			for (let i = 0; i < response.length; i++) {
				if (items.some(x => x.address.toUpperCase() === response[i].address.toUpperCase()) == false) {
					items.push(response[i]);
				}
			}
		}

		return items.sort((a, b) => {
			const nameA = a.symbol?.toUpperCase();
			const nameB = b.symbol?.toUpperCase();

			if (a.pinned && !b.pinned) {
				return -1;
			} else if (!a.pinned && b.pinned) {
				return 1;
			}

			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			if (a.logo && !b.logo) {
				return -1
			} else if (!a.logo && b.logo) {
				return 1;
			}

			return 0;
		});
	}

	const getOptionsDelayed = React.useCallback(
		debounce(({ text, options }, callback) => {
			getTokenList(text, options).then(callback);
		}, 500),
		[]
	);

	const handlePinToken = async (evt, address, pinned) => {
		evt.stopPropagation();
		var token = await fetchData(`/api/user/pintoken`, "post", { address: address, pinned });
		if (pinned) {
			setOptions([token, ...options.filter(x => x.address.toUpperCase() != token.address.toUpperCase())]);
		} else {
			setOptions([...options.filter(x => x.address.toUpperCase() != token.address.toUpperCase())]);
		}
	}

	React.useEffect(() => {
		setOptions(tokenList.tokens);
	}, [tokenList]);

	React.useEffect(() => {
		if (inputValue.length > 0) {
			setLoading(true );
			getOptionsDelayed({ text: inputValue, options }, (filteredOptions) => {
				setFilteredOptions(filteredOptions);
				setLoading(false || tokenList.loading);
			});
		} else {
			setLoading(true);
			setFilteredOptions(options);
			setLoading(false || tokenList.loading);
		}
	}, [inputValue, getOptionsDelayed, options, tokenList]);

	return (
		<Autocomplete
			value={value}
			onChange={(event, newValue) => {
				onSelect(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			isOptionEqualToValue={(option, value) => option?.address === value.address}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			getOptionLabel={(option) => `${option.symbol} ${option.address}`}
			filterOptions={filterOptions}
			renderOption={(props, option) => (
				<Box component="li" width="100%" {...props}>
					<Stack direction="row" width="100%" justifyContent="space-between">
						<Stack direction="row" alignItems="center" spacing={2}>
							<Box>
								<TokenLogo token={option} size={30} />
							</Box>
							<Box>{option.name}</Box>
							<Chip label={option.symbol} />
						</Stack>
						<Stack alignItems="center">
							<EthAmount value={option.price?.nativePrice} />
						</Stack> 
						<Stack direction="row" alignItems="center" spacing={1}>
						<ShortAddress value={option.address} />
							{option.pinned ?
								<Tooltip title="Unpin Token">
									<IconButton onClick={(evt) => { handlePinToken(evt, option.address, false); }}>
										<StarIcon />
									</IconButton>
								</Tooltip> :
								<Tooltip title="Pin Token">
									<IconButton onClick={(evt) => { handlePinToken(evt, option.address, true); }}>
										<StarOutlineIcon />
									</IconButton>
								</Tooltip>
							}
						</Stack>
					</Stack>
				</Box>
			)}
			options={filteredOptions}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Copy/Paste smart contract address or type token name"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
}