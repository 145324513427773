import React from "react";
import { WalletCard } from "./wallets/WalletCard"; // Make sure this import points to the correct file
import { CreateWallet } from "./wallets/CreateWallet";
import { PkDialog } from "./wallets/PkDialog";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { PageSection } from './shared/PageSection'
import { PageHeader } from "./shared/FormattingComponents"
import { useWallets } from "./WalletContext";
import { usePrice } from "./PriceContext";

export const Wallet = ({ setSelectedToken }) => {
	const { loading, error, walletCount, linkedWallet, wallet1, wallet2, wallet3, wallet4, wallet5 } = useWallets();
	const [walletToShow, setWalletToShow] = React.useState(-1);
	const { native } = usePrice(); 

	if (loading) {
		return <div>Loading wallet information...</div>;
	}

	if (error) {
		return <div>Error fetching wallet data: {error.message}</div>;
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<PageHeader pageName="Wallets" helpUrl="https://unodex.gitbook.io/docs/unodex-trading-app/wallet" />
			<PkDialog walletToShow={walletToShow} setWalletToShow={setWalletToShow} />
			<Grid container spacing={2}>
				{native.symbol !== "SOL" ? (
					<Grid item xs={12} md={6} lg={4}>
						<PageSection>
							<WalletCard
								wallet={linkedWallet}
								setWalletToShow={setWalletToShow}
								setSelectedToken={setSelectedToken}
							/>
						</PageSection>
					</Grid>
				) : <></>}
				<Grid item xs={12} md={6} lg={4}>
					<PageSection>
						<WalletCard
							wallet={wallet1}
							setWalletToShow={setWalletToShow}
							setSelectedToken={setSelectedToken}
						/>
					</PageSection>
				</Grid>
				{walletCount >= 2 ?
					<Grid item xs={12} md={6} lg={4}>
						<PageSection>
							<WalletCard
								wallet={wallet2}
								setWalletToShow={setWalletToShow}
								setSelectedToken={setSelectedToken}
							/>
						</PageSection>
					</Grid> :
					<></>
				}
				{walletCount >= 3 ?
					<Grid item xs={12} md={6} lg={4}>
						<PageSection>
							<WalletCard
								wallet={wallet3}
								setWalletToShow={setWalletToShow}
								setSelectedToken={setSelectedToken}
							/>
						</PageSection>
					</Grid> :
					<></>
				}
				{walletCount >= 4 ?
					<Grid item xs={12} md={6} lg={4}>
					<PageSection>
							<WalletCard
								wallet={wallet4}
								setWalletToShow={setWalletToShow}
								setSelectedToken={setSelectedToken}
							/>
						</PageSection>
					</Grid> :
					<></>
				}
				{walletCount >= 5 ?
					<Grid item xs={12} md={6} lg={4}>
					<PageSection>
						<WalletCard
							wallet={wallet5}
								setWalletToShow={setWalletToShow}
								setSelectedToken={setSelectedToken}
						/>
					</PageSection>
					</Grid> :
					<></>
				}

				{walletCount < 5 ?
					<Grid item xs={12} md={6} lg={4}>
						<PageSection sx={{ height: "100%" }}>
							<CreateWallet walletCount={walletCount} />
						</PageSection>
					</Grid> :
					<></>
				}
			</Grid>
		</Box>
	);
};
