import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useFetchData } from "../FetchDataContext";

export const DeleteFunction = ({ idToDelete, setIdToDelete }) => {
	const { fetchData, setLatestOrder } = useFetchData();
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		if (idToDelete) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [idToDelete]);


	const handleClose = () => {
		setIdToDelete(null);
	};

	const handleConfirm = async () => {
		if (idToDelete) {
			const deleted = await fetchData(`/api/function/${idToDelete}`, "delete");
			setLatestOrder(deleted);
		}
		setIdToDelete(null);
	};

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={handleClose}
			>
				<DialogTitle id="alert-dialog-title">
					{"Delete this order?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Once an order has been deleted, it will no longer be in the execution queue.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirm} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}