import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from '../shared/TabPanel'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StyledTableCell } from '../shared/StyledTable'
import { EthAmount } from "../shared/EthAmount";
import { ShortDecimal } from '../shared/FormattingComponents'

export const UnclaimedRewards = ({ rewardsData }) => {
	const [activeTab, setActiveTab] = React.useState(0);

	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	return (
	  <>
		<Typography variant="h3">Unclaimed Rewards</Typography>
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={activeTab} onChange={handleTabChange}>
					<Tab label="Linked Wallet" {...a11yProps(0)} />
					{rewardsData?.custodialWallets.map((row, index) => { return (
						<Tab key={`tab_${index}`} label={index === 0 ? `Main Wallet` : `Wallet ${index + 1}`} {...a11yProps(index + 1)} />
					)})}
				</Tabs>
			</Box>
			<TabPanel value={activeTab} index={0}>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								<StyledTableCell align="center">Epoch</StyledTableCell>
								<StyledTableCell align="right">Circulating</StyledTableCell>
								<StyledTableCell align="right">Holding</StyledTableCell>
								<StyledTableCell align="right">Share %</StyledTableCell>
								<StyledTableCell align="right">Total Reward</StyledTableCell>
								<StyledTableCell align="right">Reward Share</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rewardsData?.linkedWallet.unclaimedRewards.length === 0 ?
								<TableRow key={-1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
									<TableCell colSpan={6} component="th" scope="row">No unclaimed rewards</TableCell>
								</TableRow> :
								rewardsData?.linkedWallet.unclaimedRewards.map((row, index) => { return (
									<TableRow key={`panel_${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
										<TableCell component="th" scope="row">{ row.epoch }</TableCell>
										<TableCell align="right"><ShortDecimal value={row.circulating} /></TableCell>
										<TableCell align="right"><ShortDecimal value={row.holding}/></TableCell>
										<TableCell align="right"><ShortDecimal value={row.sharePercent * 100}/></TableCell>
										<TableCell align="right"><EthAmount value={row.totalReward} /></TableCell>
										<TableCell align="right"><EthAmount value={row.rewardShare} /></TableCell>
									</TableRow>
								)})
							}
								
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			{rewardsData?.custodialWallets.map((wallet, index) => { return (
				<TabPanel value={activeTab} index={index + 1}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell align="center">Epoch</StyledTableCell>
									<StyledTableCell align="right">Circulating</StyledTableCell>
									<StyledTableCell align="right">Holding</StyledTableCell>
									<StyledTableCell align="right">Share %</StyledTableCell>
									<StyledTableCell align="right">Total Reward</StyledTableCell>
									<StyledTableCell align="right">Reward Share</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{wallet.unclaimedRewards.length === 0 ?
									<TableRow key={-1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
										<TableCell colSpan={6} component="th" scope="row">No unclaimed rewards</TableCell>
									</TableRow> :
									wallet.unclaimedRewards.map((row, index) => { return (
										<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
											<TableCell component="th" scope="row">{row.epoch}</TableCell>
											<TableCell align="right"><ShortDecimal value={row.circulating} /></TableCell>
											<TableCell align="right"><ShortDecimal value={row.holding} /></TableCell>
											<TableCell align="right"><ShortDecimal value={row.sharePercent * 100} /></TableCell>
											<TableCell align="right"><EthAmount value={row.totalReward} /></TableCell>
											<TableCell align="right"><EthAmount value={row.rewardShare} /></TableCell>
										</TableRow>
									)})
								}

							</TableBody>
						</Table>
					</TableContainer>
				</TabPanel>
			)})}
		</Box>
	  </>
  );
};
