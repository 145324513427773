import * as React from 'react';
import Slider from '@mui/material/Slider';

export const Slippage = ({ value, onChange }) => {

	const marks = [
		//{
		//	value: -1,
		//	label: 'Auto',
		//},
		{
			value: 0,
			label: '0%',
		},
		{
			value: 10,
			label: '10%',
		},
		{
			value: 20,
			label: '20%',
		},
		{
			value: 30,
			label: '30%',
		},
		{
			value: 40,
			label: '40%',
		},
		{
			value: 50,
			label: '50%',
		},
	];

	const handleChange = (event, value) => {
		onChange(value);
	}

	const valuetext = (value) => {
		return value === -1 ? "Auto" : `${value}%`;
	}

	return (
		<>
			<label>Slippage</label>
			<Slider
				aria-label="Slippage"
				getAriaValueText={valuetext}
				step={0.1}
				min={0}
				max={50}
				valueLabelDisplay="auto"
				marks={marks}
				onChange={handleChange}
				/>
		</>
	);
}