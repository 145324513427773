import React from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { EthAmount } from "../shared/EthAmount";
import { useFetchData } from "../FetchDataContext";
import { Loading } from "../shared/FormattingComponents";
import Typography from '@mui/material/Typography';

export const UnclaimedReward = () => {
	const { rewardsData } = useFetchData();

	return (
		<Box sx={{ height: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
			<Stack justifyContent="space-evenly" alignItems="center" sx={{ width: "100%" }} spacing={2}>
				<Typography variant="h3">Unclaimed Rewards</Typography>
				{rewardsData.loading ?
					<Loading /> :
					<Stack direction="row" spacing={1}>
						<Card variant="outlined" sx={{ width: "100px", padding: "5px 0" }}>
							<Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
								<EthAmount fontSize="normal" alignItems="center" forceEth value={rewardsData.data?.custodialWallets?.reduce((total, row) => total + row.rewardsOwed, rewardsData.data.linkedWallet.rewardsOwed)} />
							</Stack>
						</Card>
						<Card variant="outlined" sx={{ width: "100px", padding: "5px 0" }}>
							<Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
								<NavLink tag={Link} to="/rewards" title="Trade">
									<Button variant="contained" className="btn-primary" disabled={rewardsData.data?.unclaimedRewards == 0}>
										Claim
									</Button>
								</NavLink>
							</Stack>
						</Card>
					</Stack>
				}
			</Stack>
		</Box>
	);
};
