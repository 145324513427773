import React, { useEffect, useState } from "react";
import { ClaimBar } from "./claimRewards/ClaimBar";
import { UnclaimedRewards } from "./claimRewards/UnclaimedRewards";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { PageSection } from './shared/PageSection';
import { EthAmount } from "./shared/EthAmount";
import { PageHeader } from "./shared/FormattingComponents";
import { useFetchData } from "./FetchDataContext";

export const Rewards = () => {
	const { rewardsData, fetchData } = useFetchData();

	if (rewardsData.error) {
		return <div>Error: {rewardsData.error.message}</div>;
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<PageHeader pageName="Claim Rewards" helpUrl="https://unodex.gitbook.io/docs/unodex-trading-app/claim-rewards" />
			<Grid container spacing="20px">
				<Grid item xs={12} lg={8}>
					<PageSection>
						<ClaimBar />
					</PageSection>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Grid container spacing="20px">
						<Grid item xs={6} lg={12}>
							<PageSection>
								<Stack justifyContent="center" sx={{ width: "100%", height: "100%" }}>
									<EthAmount fontSize="normal" value={rewardsData.data?.totalEarned} />
									<p>Total earned rewards</p>
								</Stack>
							</PageSection>
						</Grid>
						<Grid item xs={6} lg={12}>
							<PageSection>
								<Stack justifyContent="center" sx={{ width: "100%", height: "100%"}}>
									<EthAmount fontSize="normal" value={rewardsData.data?.rewardsOwed} />
									<p>Unclaimed rewards</p>
								</Stack>
							</PageSection>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<PageSection>
						<UnclaimedRewards rewardsData={rewardsData.data} />
					</PageSection>
				</Grid>
			</Grid>
		</Box>
	);

};
