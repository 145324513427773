import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { Layout } from "./components/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react';
import { useDisconnect, useAccount, useSignMessage } from 'wagmi';

import { NavMenu } from "./components/NavMenu";
import { Dashboard } from "./components/Dashboard";
import { Trade } from "./components/Trade";
import { Snipe } from "./components/Snipe";
import { Shotgun } from "./components/Shotgun";
import { CopyTrades } from "./components/CopyTrades";
import { Referrals } from "./components/Referrals";
import { Rewards } from "./components/Rewards";
import { Admin } from "./components/Admin";
import { Wallet } from "./components/Wallet";
import { Notifications } from "./components/Notifications"
import { GasPrice } from "./components/shared/GasPrice"
import { Home } from "./components/Home";
import Button from '@mui/material/Button';
import Blockies from 'react-blockies';
import DarkModeToggle from './components/DarkModeToggle';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { PriceProvider } from './components/PriceContext';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { WalletProvider } from "./components/WalletContext";
import { FetchDataProvider } from "./components/FetchDataContext";

const drawerWidth = 80;

export const useSessionState = (defaultValue, key) => {
	const [value, setValue] = React.useState(() => {
		const stickyValue = window.sessionStorage.getItem(key);
		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
	});
	React.useEffect(() => {
		window.sessionStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);
	return [value, setValue];
}

export const useLocalState = (defaultValue, key) => {
	const [value, setValue] = React.useState(() => {
		const stickyValue = window.localStorage.getItem(key);
		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
	});
	React.useEffect(() => {
		window.localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);
	return [value, setValue];
}

export const App = () => {
	const [connecting, setConnecting] = useState(false);
	const [sessionId, setSessionId] = useState("");
	const [isAdmin, setIsAdmin] = useState(false);
	const [isTester, setIsTester] = useState(false);
	const [notificationsOpen, setNotificationsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [notificationsLastOpened, setNotificationsLastOpened] = useLocalState(null, "notificationsLastOpened")

	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const [isMobileNavCollapsed, setIsMobileNavCollapsed] = useState(true);

	const [walletsToRefresh, setWalletsToRefresh] = useState([]);
	const [tokenList, setTokenList] = useState({
		loading: true,
		error: null,
		tokens: []
	});

	const [connection, setConnection] = useSessionState(
		{ connected: false, account: "", signature: "" },
		"connection"
	);

	const [chain, setChain] = useLocalState("eth", "chain");
	const [selectedToken, setSelectedToken] = useState(null)

	const { disconnect } = useDisconnect();
	const { open: openDialog } = useWeb3Modal();
	const { address, isDisconnected, connector: activeConnector } = useAccount();
	const { open } = useWeb3ModalState();
	const { signMessage } = useSignMessage({
		message: sessionId,
		onSuccess(data) {
			setConnecting(false);
			setConnection({
				connected: true,
				account: address,
				sessionId: connection.sessionId,
				signature: data,
			});
		},
		onError(error) {
			setConnecting(false);
			setConnection({
				connected: false,
				account: "",
				sessionId: connection.sessionId,
				signature: "",
			});
		},
	});

	React.useEffect(() => {
		const handleConnectorUpdate = ({ account, chain }) => {
			if (account) {
				setConnection({
					connected: false,
					account: "",
					sessionId: connection.sessionId,
					signature: "",
				});
			} else if (chain) {
				console.log("new chain", chain);
			}
		};

		if (activeConnector) {
			activeConnector.on("change", handleConnectorUpdate);
		}

		return () => activeConnector?.off("change", handleConnectorUpdate);
	}, [activeConnector]);

	useEffect(() => {
		if (connecting) {
			connect();
		}
	}, [connecting, sessionId, open]);

	const fetchData = useCallback(
		async (url, verb, data) => {
			try {
				if (connection.connected) {
					switch (verb) {
						case "post":
							const postResponse = await axios.post(url, data, {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${connection.signature}`,
									"X-User-ID": connection.account,
									"X-Chain-ID": chain
								},
							});
							return postResponse.data;

						case "update":
							const updateResponse = await axios.update(url, {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${connection.signature}`,
									"X-User-ID": connection.account,
									"X-Chain-ID": chain
								},
							});
							return updateResponse.data;

						case "delete":
							const deleteResponse = await axios.delete(url, {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${connection.signature}`,
									"X-User-ID": connection.account,
									"X-Chain-ID": chain
								},
							});
							return deleteResponse.data;

						default:
							const getResponse = await axios.get(url, {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${connection.signature}`,
									"X-User-ID": connection.account,
									"X-Chain-ID": chain
								},
							});
							return getResponse.data;
					}
				} else {
					return false;
				}
			} catch (error) {
				if (error.response.status == 401) {
					console.log("401 error disconnecting session");
					setConnecting(false);
					setConnection({
						connected: false,
						account: "",
						sessionId: "",
						signature: "",
					});
				} else {
					throw error;
				}
			}
		},
		[connection, chain]
	);

	useEffect(() => {
		const getTokenList = async () => {
			if (connection.connected) {
				try {
					setTokenList({
						loading: true,
						error: null,
						tokens: []
					});

					const data = await fetchData("/api/user/tokenlist", "GET", null);

					setTokenList({
						loading: false,
						error: null,
						tokens: data.tokens
					});

				} catch (err) {
					setTokenList({
						loading: false,
						error: err,
						tokens: []
					});
				}
			} else {
				setTokenList({
					loading: false,
					error: null,
					tokens: []
				});
			}
		};

		if (connection.connected) {
			fetchData("/api/session/check", "get").then(() => {
				getTokenList();
			})
		}
	}, [connection, chain]);

	const connect = async () => {
		try {
			const response = await axios.get("/api/session");
			let sid = response.data;
			console.log(sid)
			if (sid != sessionId) {
				await setSessionId(sid);
				return;
			}

			if (isDisconnected) {
				await openDialog();
			}

			if (!isDisconnected) {
				let signed = connection.signature;
				if (!signed) {
					signMessage();
					return;
				}

				setConnecting(false);
				setConnection({
					connected: true,
					account: address,
					sessionId: sid,
					signature: signed,
				});
			}
		} catch (err) {
			setConnecting(false);
		}
	};

	const onClickConnect = async () => {
		setConnecting(true);
		setConnection({
			connected: false,
			account: connection.account,
			sessionId: connection.sessionId,
			signature: connection.signature,
		});
	};

	const onClickDisconnect = async () => {
		await disconnect();
		setConnecting(false)
		setConnection({
			connected: false,
			account: "",
			sessionId: "",
			signature: "",
		});
	};

	const handleShowNotifications = () => {
		setNotificationsLastOpened(new Date());
		setNotificationsOpen(true);
	};

	const handleHideNotifications = () => {
		setNotificationsOpen(false);
	}

	const handleDrawerClose = () => {
		setIsNavCollapsed(true);
		setIsMobileNavCollapsed(true);
	};

	const handleMobileDrawerOpen = () => {
		setIsMobileNavCollapsed(false);
	};

	const handleMobileDrawerClose = () => {
		setIsNavCollapsed(true);
		setIsMobileNavCollapsed(true);
	};

	const handleChainChange = (event) => {
		setChain(event.target.value);
	};

	const unreadNotifications = (notifications.filter(x => new Date(x.createDate) > new Date(notificationsLastOpened)));

	return (
		<FetchDataProvider connection={connection} chain={chain} fetchData={fetchData} setNotifications={setNotifications} setWalletsToRefresh={setWalletsToRefresh} setIsAdmin={setIsAdmin} setIsTester={setIsTester}>
			<PriceProvider>
				<WalletProvider walletsToRefresh={walletsToRefresh}>
					<Box style={{ backgroundColor: "background.main"}} >
						<ToastContainer position="bottom-right" />
						<Layout>
							<Box sx={{ display: 'flex' }}>
								<CssBaseline />
								<AppBar 
									color="topNav"
									position="fixed"
									sx={{
										width: { sm: `calc(100% - ${drawerWidth}px)` },
										ml: { sm: `${drawerWidth}px` }
							
									}}
								>
									<Toolbar>
										<Stack
											sx={{ width: "100%" }}
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											spacing={1}
										>
											<Stack direction="row" alignItems="center">
												<IconButton
													color="inherit"
													aria-label="open drawer"
													edge="start"
													onClose={handleMobileDrawerClose}
													onClick={handleMobileDrawerOpen}
													sx={{ mr: 2, display: { sm: 'none' } }}
												>
													<MenuIcon />
												</IconButton>
												{connection.connected ? 
													<GasPrice />
													: <></>
												}
											</Stack>
											<Stack
												direction="row"
												justifyContent="center"
												alignItems="center"
												spacing={1}
											>
												{connection.connected && connection.account ? (
													<>
														<Select size="small" value={chain} onChange={handleChainChange}>
															<MenuItem value="eth">
																<Stack direction="row" spacing={1} alignContent="center" alignItems="center">
																	<Avatar src="/img/eth.png" sx={{ width: 20, height: 20, padding: "1px" }} />
																	<ListItemText primary="Ethereum" sx={{ display: { "xs": "none", "md": "block" } }} />
																</Stack>
															</MenuItem>
															<MenuItem value="sol">
																	<Stack direction="row" spacing={1} alignContent="center" alignItems="center">
																		<Avatar src="/img/sol.png" sx={{ width: 20, height: 20, padding: "1px" }} />
																	<ListItemText primary="Solana" sx={{ display: { "xs": "none", "md": "block" } }} />
																	</Stack>
															</MenuItem>
															<MenuItem value="bsc">
																<Stack direction="row" spacing={1} alignContent="center" alignItems="center">
																	<Avatar src="/img/bsc.svg" sx={{ width: 20, height: 20, padding: "1px" }} />
																	<ListItemText primary="BNB Smart Chain" sx={{ display: { "xs": "none", "md": "block" } }} />
																</Stack>
															</MenuItem>
														</Select>
														<Badge badgeContent={unreadNotifications.length} color="success">
															{notificationsOpen ? (
																<NotificationsIcon sx={{ cursor: "pointer"}} onClick={handleHideNotifications} />
															) : (<NotificationsNoneIcon sx={{ cursor: "pointer" }} onClick={handleShowNotifications} />)
														}
														</Badge>
														<Tooltip title={`${connection.account?.substring(0, 6)}...${connection.account?.substring(35)}`}>
															<Avatar sx={{ width: 31, height: 31 }}>
																<Blockies
																	seed={connection.account}
																	size={10}
																	scale={2}
																	color="#dfe"
																	bgColor="transparent"
																	spotColor="#abc"
																/>
															</Avatar>
														</Tooltip>
														<Button variant="contained" onClick={onClickDisconnect} sx={{ minWidth: 0 }}>
															<Stack direction="row">
																<LogoutIcon sx={{ display: { sm: "none" } }} fontSize="small" />
																<Box sx={{ display: { xs: "none", sm: "inline-block" } }}>Disconnect</Box>
															</Stack>
														</Button>
													</>
												) : (<>
													<Button variant="contained" onClick={onClickConnect}>Connect</Button>
												</>)}
												<DarkModeToggle />
											</Stack>
										</Stack>
									</Toolbar>
									<Drawer
										variant="temporary"
										open={notificationsOpen}
										onClose={handleHideNotifications}
										anchor="top"
										ModalProps={{
											keepMounted: true, // Better open performance on mobile.
										}} sx={{
											zIndex: "1000 !important",
											'& .MuiModal-backdrop': { opacity: "0 !important" },
											'& .MuiPaper-root': {
												xs: { top: "50px", left: "10vw", zIndex: "1000 !important" },
												sm: { top: "64px", left: "30vw", zIndex: "1000 !important" },
												me: { top: "64px", left: "50vw", zIndex: "1000 !important" },
											}
										}}
						
									>
										<div>
											<Notifications notifications={notifications} />
										</div>
									</Drawer>
								</AppBar>
								<Box
									component="nav"
									sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
									aria-label="mailbox folders"
								>
									{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
									<Drawer
										variant="temporary"
										open={!isMobileNavCollapsed}
										onClose={handleMobileDrawerClose}
										ModalProps={{
											keepMounted: true, // Better open performance on mobile.
										}}
										sx={{
											display: { xs: 'block', sm: 'none' },
											'& .MuiDrawer-paper': { boxSizing: 'border-box',  minWidth: "80px", backgroundColor: 'leftNav.main' },
										}}
									>
										<div>
											<NavMenu alwaysExpanded={true} isNavCollapsed={isMobileNavCollapsed} setIsNavCollapsed={setIsMobileNavCollapsed} isAdmin={isAdmin} isTester={isTester} />
										</div>
									</Drawer>
									<Drawer open={true} color="leftNav" variant={isNavCollapsed ? "permanent" : "temporary"} onClose={handleDrawerClose} sx={{
										display: { xs: 'none', sm: 'block' },
										'& .MuiDrawer-paper': { boxSizing: 'border-box', minWidth: "80px", backgroundColor: 'leftNav.main' },
									}}>
										<div>
											<NavMenu alwaysExpanded={false} isNavCollapsed={isNavCollapsed} setIsNavCollapsed={setIsNavCollapsed} isAdmin={isAdmin} isTester={isTester} />
										</div>
									</Drawer>
								</Box>
								<Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: "background.main", minHeight: "100vh", width: "calc(100vw - 20px)", width: "calc(100vw - 30px)", position: "absolute", padding: { xs: "60px 30 0 30px", sm: "80px 0 0 90px" } }}>
									<Routes>
										<Route
											path="/"
											element={
												connection.connected ? (
													<Navigate to="/dashboard" />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/home"
											element={
												connection.connected ? (
													<Navigate to="/dashboard" />
												) : (
													<Home />
												)
											}
										/>
										<Route
											path="/dashboard"
											element={
												connection.connected ? (
													<Dashboard selectedToken={selectedToken} setSelectedToken={setSelectedToken} />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/trade"
											element={
												connection.connected ? (
													<Trade tokenList={tokenList} selectedToken={selectedToken} setSelectedToken={setSelectedToken} />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/snipe"
											element={
												connection.connected ? (
													<Snipe tokenList={tokenList} selectedToken={selectedToken} setSelectedToken={setSelectedToken} />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/shotgun"
											element={
												connection.connected && chain !== "eth" ? (
													<Shotgun setSelectedToken={setSelectedToken} />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/copy-trades"
											element={
												connection.connected ? (
													<CopyTrades />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/referrals"
											element={
												connection.connected ? (
													<Referrals />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/rewards"
											element={
												connection.connected ? (
													<Rewards />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/wallet"
											element={
												connection.connected ? (
													<Wallet setSelectedToken={setSelectedToken} />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
										<Route
											path="/admin"
											element={
												connection.connected && isAdmin ? (
													<Admin />
												) : (
													<Navigate to="/home" />
												)
											}
										/>
									</Routes>
									<Box sx={{ height: "50px"}}></Box>
									<Stack direction="row" sx={{ position: "absolute", left: { xs: "30px", sm: "90px" }, right: 0, bottom: 0 }} justifyContent="space-between" alignItems="center">
										<Typography variant="subtitle1">2024 &copy; UNODEX</Typography>
										<Typography variant="subtitle1">All rights reserved</Typography>
									</Stack>
								</Box>
							</Box>
						</Layout>
					</Box>
				</WalletProvider>
			</PriceProvider>
		</FetchDataProvider>
	);
};