import React from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack'
import { WalletToggle } from "../shared/WalletToggle";
import { Slippage } from '../shared/Slippage'
import { ShortDecimal } from '../shared/FormattingComponents'
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import { useFetchData } from "../FetchDataContext";
import { Flashbots } from "../shared/Flashbots";
import { parseUnits, formatUnits } from 'viem'
import { toast } from "react-toastify";
import { usePrice } from "../PriceContext";
import { required, requiredValidDecimal, validDecimal } from "../shared/Validation";

export const SniperWidget = ({ tokenInfo }) => {
	const { fetchData, chain, setLatestOrder } = useFetchData();
	const { native } = usePrice();

	const [method, setMethod] = React.useState("auto");
	const [walletIndex, setWalletIndex] = React.useState(1);
	const [wallet, setWallet] = React.useState();
	const [ethAmount, setEthAmount] = React.useState(0);
	const [tokenAmount, setTokenAmount] = React.useState(0);
	const [contractFunctionSniper, setContractFunctionSniper] = React.useState("");
	const [lpSnipe, setLpSnipe] = React.useState(false);
	const [firstOrFail, setFirstOrFail] = React.useState(false);
	const [blockDelay, setBlockDelay] = React.useState(0);
	const [slippage, setSlippage] = React.useState(25);
	const [bribe, setBribe] = React.useState(chain == "sol" ? 30000 : 20);
	const [useFlashbots, setUseFlashbots] = React.useState(false);
	const [canSubmit, setCanSubmit] = React.useState(false);

	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	// Form Valid
	React.useEffect(() => {

		let formData = getFormData();
		let valid = true;

		if (required(formData?.address)) {

		} else {
			valid = false;
		}

		if (validDecimal(formData.tokenAmount)) {

		} else {
			valid = false;
		}

		if (requiredValidDecimal(formData.ethAmount)) {

		} else {
			valid = false;
		}

		setCanSubmit(valid);
	}, [
		tokenInfo,
		walletIndex,
		ethAmount,
		tokenAmount,
	]);

	const getFormData = () => {
		return {
			wallet: walletIndex,
			method,
			ethAmount,
			tokenAmount,
			address: tokenInfo?.address,
			contractFunctionSniper,
			lpSnipe,
			firstOrFail,
			blockDelay,
			slippage,
			useFlashbots,
			bribe: useFlashbots || chain === "sol" ? bribe : 0
		};
	}

	const handleSnipeSubmit = async () => {
		const formDataToSubmit = getFormData();

		try {
			const response = await fetchData("/api/function/sniper", "post", formDataToSubmit);
			setLatestOrder(response);
			showToast(`Snipe submitted successfully!`);
		} catch (err) {
			showToast(
				`Error submitting snipe: ${err.message}`,
				"error"
			);
		}
	};

	const sanitizeInput = (input) => {
		return input.trim().replace(/[^0-9.e\-]/g, "");
	};

	const handleSetMethod = (e, value) => { setMethod(e.target.value); }
	const handleSetWallet = (value, current) => {
		setWalletIndex(value);
		setWallet(current);
	}
	const handleSetEthAmount = (e, value) => { setEthAmount(sanitizeInput(e.target.value)); }
	const handleSetTokenAmount = (e, value) => { setTokenAmount(sanitizeInput(e.target.value)); }
	const handleSetContractFunctionSniper = (e, value) => { setContractFunctionSniper(e.target.value); }
	const handleSetLpSnipe = (e, value) => { setLpSnipe(value); }
	const handleSetFirstOrFail = (e, value) => { setFirstOrFail(value); }
	const handleSetBlockDelay = (e, value) => { setBlockDelay(sanitizeInput(e.target.value)); }
	const handleSetSlippage = (e, value) => { setSlippage(e.target.value); }

	return (
		<>
			<WalletToggle value={walletIndex} onChange={handleSetWallet} />
			<Stack xs={{ width: "100%" }} spacing={2}>
				<Stack direction="row" justifyContent="space-between" alignItems="center" >
					<RadioGroup onChange={handleSetMethod} row value={method}>
						<FormControlLabel value="auto" control={<Radio />} label="Auto" />
						<FormControlLabel value="manual" control={<Radio />} label="Manual" />
					</RadioGroup>
					{walletIndex > 0 ? <ShortDecimal value={formatUnits(wallet?.nativeBalance ?? 0, native.decimals)} unit={native.symbol} /> : <></> }
					
				</Stack>
				<Stack direction="row" spacing={2}>
					<TextField label="Max Spend" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSetEthAmount} value={ethAmount} InputProps={{
						endAdornment: <InputAdornment position="end">{native.symbol}</InputAdornment>,
					}} />
					<TextField label="Max Purchase Per Wallet" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSetTokenAmount} value={tokenAmount} InputProps={{
						endAdornment: <InputAdornment position="end">{tokenInfo?.symbol ?? ""}</InputAdornment>,
					}} />
				</Stack>
				{method == "manual" ?
					<>
						<Stack direction="row" spacing={2} xs={{ width: "100%" }} justifyContent="space-evenly">
							<FormControlLabel control={<Switch checked={lpSnipe} onChange={handleSetLpSnipe} />} label="LP Snipe" />
							<FormControlLabel control={<Switch checked={firstOrFail} onChange={handleSetFirstOrFail} />} label="First or fail" />
						</Stack>
						<Stack direction="row" spacing={2}>
							<TextField label="Contract function snipe" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSetContractFunctionSniper} value={contractFunctionSniper} />
							<TextField label="Block delay" variant="outlined" size="small" margin="dense" fullWidth onChange={handleSetBlockDelay} value={blockDelay} />
						</Stack>
						<Box sx={{ padding: "0 20px" }}>
							<Slippage value={slippage} onChange={handleSetSlippage}></Slippage>
						</Box>
						<Flashbots useFlashbots={useFlashbots} setUseFlashbots={setUseFlashbots} bribe={bribe} setBribe={setBribe} />
					</> :
					<></>
				}

				<Button onClick={handleSnipeSubmit} variant="contained" fullWidth
					disabled={canSubmit == false}>
					Create Snipe
				</Button>
			</Stack>
		</>
	)
};
