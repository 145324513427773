import React, { useState } from "react";
import { BuySellWidget } from "./trade/BuySellWidget";
import { OrdersHistoryPositions } from "./trade/OrdersHistoryPositions";
import { TopTradedTokens } from "./trade/TopTradedTokens";
import { TradeHistory } from "./trade/TradeHistory";
import { TradingViewChart } from './shared/TradingViewChart';
import { TokenLookup } from "./shared/TokenLookup";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { PageHeader } from "./shared/FormattingComponents";
import { PageSection } from './shared/PageSection';
import { useFetchData } from "./FetchDataContext";

export const Trade = ({ tokenList, selectedToken }) => {
	const { orderData, setLatestOrder } = useFetchData();

	const [symbol, setSymbol] = useState(selectedToken?.address ?? "0x185ece9Bc75164F9FC0fbE44738E8dd1863f8464");
	const [inputValue, setInputValue] = useState(selectedToken);
	const [isBuySellWidgetEnabled, setIsBuySellWidgetEnabled] = useState(false);
	const [activeTab, setActiveTab] = React.useState(0);
	const [sellAmount, setSellAmount] = useState(0);

	const onDeleted = (id) => {
		let d = orderData.data.find(x => x.id == id)
		d.deleted = true;
		setLatestOrder(d);
	}

	const disableWidget = () => {
		setIsBuySellWidgetEnabled(false);
	}

	const enableWidget = () => {
		setIsBuySellWidgetEnabled(true);
	}

	const setSelectedToken = (token) => {
		handleSearchButtonClick(token);
	}

	const setFastSell = (row) => {
		setActiveTab(1);
		setSellAmount(row.toAmount);
		handleSearchButtonClick(row.token);
	}

	const handleSearchButtonClick = async (token) => {
		if (token) {
			setInputValue(token);
			enableWidget();
			setSymbol(token.address);
		} else {
			disableWidget();
		}
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<PageHeader pageName="Trade" helpUrl="https://unodex.gitbook.io/docs/unodex-trading-app/trades-trading-section" />
			<Grid container spacing="20px">
				<Grid item xs={12} md={12} lg={9}>
					<Grid container spacing="20px">
						<Grid item xs={12} md={12} lg={12}>
							<PageSection sx={{ minHeight: "72px" }}>
								<TokenLookup value={inputValue} tokenList={tokenList} onSelect={handleSearchButtonClick}></TokenLookup>
							</PageSection>
						</Grid>
						<Grid item xs={12} md={12} lg={4}>
							<PageSection sx={{ minHeight: "458px" }}>
								<TopTradedTokens setSelectedToken={setSelectedToken} />
							</PageSection>
						</Grid>
						<Grid item xs={12} md={12} lg={8}>
							<PageSection sx={{ minHeight: "458px" }}>
								<TradingViewChart symbol={symbol} style={{ height: "440px" }} />
							</PageSection>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={12} lg={3}>
					<PageSection sx={{ minHeight: "550px" }}>
						<BuySellWidget
							isEnabled={isBuySellWidgetEnabled}
							inputValue={inputValue}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							sellAmount={sellAmount}
							setSellAmount={setSellAmount}
						/>
					</PageSection>
				</Grid>
				<Grid item xs={12} md={12} lg={9}>
					<PageSection sx={{ minHeight: "250px" }}>
						<OrdersHistoryPositions
							onDeleted={onDeleted}
							setSelectedToken={setSelectedToken}
							setFastSell={setFastSell}
						/>
					</PageSection>
				</Grid>
				<Grid item xs={12} md={12} lg={3}>
					<PageSection style={{ minHeight: "250px" }}>
						<TradeHistory />
					</PageSection>
				</Grid>
			</Grid>
		</Box>
	);
};
