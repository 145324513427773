import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import downArrow from '../../img/downArrow.png';
import { ComingSoon } from '../shared/FormattingComponents';

export class Chat extends Component {
  static displayName = Chat.name;

  render() {
    return (<>
		<ComingSoon/>
      <div className="dashSection chatDash">
        <div className="chatHeader">
        <div className="chatTitle">
			<Typography variant="h3">Chat</Typography>
        </div>
        <div className="chatAll">
          {/* <p>See All</p> */}
        </div>
        </div>
        <div className="chatContainer">
          <div className="trader">
            <p>0d0d5...5d6d00</p>
          </div>
          <div className="message">
            <p>Thank you so much..</p>
          </div>
          <div className="dropdown">
            <img src={downArrow} alt="down arrow" />
          </div>
        </div>
        <div className="chatContainer">
          <div className="trader">
            <p>0d0d5...5d6d00</p>
          </div>
          <div className="message">
            <p>Thank you so much..</p>
          </div>
          <div className="dropdown">
            <img src={downArrow} alt="down arrow" />
          </div>
        </div>
        <div className="chatContainer">
          <div className="trader">
            <p>0d0d5...5d6d00</p>
          </div>
          <div className="message">
            <p>Thank you so much..</p>
          </div>
          <div className="dropdown">
            <img src={downArrow} alt="down arrow" />
          </div>
        </div>
        
      </div>
      </>
    );
  }
}
