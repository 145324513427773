import React from "react";
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
import { useFetchData } from "../FetchDataContext";
import { parseTransactions } from "../shared/FormattingComponents"

export const Feed = () => {
	const { adminEvents } = useFetchData();

	const getIcon =(row) => {
		switch (row.eventType) {
			case "info":
				return <InfoIcon />;

			default:
				return <></>;
		}
	};

	return (
		<>
			<Typography variant="h3">Latest Events</Typography>
			{adminEvents != null && adminEvents.length > 0 ?
				<List dense disablePadding sx={{ width: '100%', height: "300px", overflow: "scroll" }}>				
					{adminEvents.map(row => {
						return (
							<ListItem alignItems="flex-start">
								<ListItemAvatar>
									{getIcon(row)}
								</ListItemAvatar>
								<ListItemText primary={parseTransactions(row.message)} secondary={row.timeStamp} />
							</ListItem>
					)})}
				</List> : <></>
			}
		</>
	);

}