import React from "react";
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Person2Icon from '@mui/icons-material/Person2';
import { PrettyNumber, tokenAvatar, Dex, ComingSoon } from './FormattingComponents';
import { toast } from "react-toastify";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SendIcon from '@mui/icons-material/Send';  
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BackHandIcon from '@mui/icons-material/BackHand';
import ApiIcon from '@mui/icons-material/Api';
import VerifiedIcon from '@mui/icons-material/Verified';
import InventoryIcon from '@mui/icons-material/Inventory';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import GroupsIcon from '@mui/icons-material/Groups';
import LockIcon from '@mui/icons-material/Lock';
import SellIcon from '@mui/icons-material/Sell';
import PaidIcon from '@mui/icons-material/Paid';
import TaskIcon from '@mui/icons-material/Task';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import LanguageIcon from '@mui/icons-material/Language';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { TransitionGroup } from 'react-transition-group';
import CardActionArea from '@mui/material/CardActionArea';
import { useFetchData } from "../FetchDataContext";
import { Token, Loading } from "./FormattingComponents"
import { EthAmount } from "./EthAmount"
import { red, orange, yellow, green, blue } from '@mui/material/colors';
import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import { parseUnits, formatUnits } from 'viem'
import { useLocalState } from "../../App";

export const NewPools = ({ setSelectedToken, maxScore = 500 }) => {
	const { poolEvents, chain, fetchData } = useFetchData();
	const navigate = useNavigate();
	const [expanded, setExpanded] = useLocalState(false, "PoolSelectQuickBuyExpanded");

	const getColor = (score) => {
		if (score < 5) {
			return "success.main";
		}
		if (score <= 15) {
			return "warning.main";
		}

		return "error.main";
	}

	const expand = async (event) => {
		event.stopPropagation();
		setExpanded(!expanded);
	}

	const quickBuy = async (event, token, amount) => {
		event.stopPropagation();
		let formData = {
			wallet: 1,
			ethAmount: amount,
			address: token,
			tokenAmount: 0,
			slippage: 10,
			orderType: "Market",
			pool: "",
			useFlashbots: false,
			bribe: 0
		};

		try {
			const response = await fetchData("/api/function/buy", "post", formData);
			showToast(`Buy order submitted successfully!`);
		} catch (error) {
			showToast(
				`Error submitting Buy order: ${error.message}`,
				"error"
			);
		}
	}

	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	const handleItemClick = (row) => {
		if (setSelectedToken && new Date(row.createdAt) < new Date()) {
			setSelectedToken(row.metadata);
			console.log(row)
			if (row.liquidity == 0 && row.chain == "eth") {
				navigate("/snipe");
			} else {

				navigate("/trade");
			}
		}
	}

	const handleSocialClick = (event, url) => {
		event.stopPropagation();
		window.open(url, '_blank');
	}

	const buildRow = (row) => {
		return (
			<Collapse in timeout={1000} key={row.address}>
				<Card variant="outlined" sx={{ margin: "0 2px 8px 0", padding: "2px", position: "relative " }} onClick={() => { handleItemClick(row) }}>
					<CardActionArea>
						{row.score >= maxScore ? <ComingSoon text=""></ComingSoon> : <></>}
						<Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }} spacing={1} alignItems="center">
						<Avatar {...tokenAvatar(row.metadata, 80)} src={`/api/token/logo/${chain}/${row.metadata?.address}`} />
						<Stack spacing="2px">
							<Stack direction="row" justifyContent="space-between" spacing={1}>
								<Stack direction="row" justifyContent="center" alignItems="center">
									<Dex value={row.dex} />
									<Token value={row.baseToken} />
									<Typography>/</Typography>
									<Token value={row.quoteToken} />
									<Box sx={{ paddingLeft: "5px" }}>{row.metadata.name}</Box>
									{row.website ? <IconButton component="div" onClick={(event) => { handleSocialClick(event, row.website) }} size="small"><LanguageIcon fontSize="10px" /></IconButton> : <></>}
									{row.twitter ? <IconButton component="div" onClick={(event) => { handleSocialClick(event, row.twitter) }} size="small"><XIcon fontSize="10px" /></IconButton> : <></>}
									{row.telegram ? <IconButton component="div" onClick={(event) => { handleSocialClick(event, row.telegram) }} size="small"><TelegramIcon fontSize="10px" /></IconButton> : <></>}
								</Stack>
								<Box>{row.latestEvent}</Box>
							</Stack>
							<Stack direction="row" flexWrap="wrap" spacing="2px">
								<Chip icon={<Tooltip title="Pool Created"><HistoryIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={<Moment fromNow>{row.createdAt}</Moment>} />
								<Chip icon={<Tooltip title="Total Supply"><InventoryIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={(<PrettyNumber value={formatUnits(row.totalSupply ?? "0", row.metadata?.decimals ?? 9)} />)} />
								<Chip icon={<Tooltip title="Market Cap"><StorefrontIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={(<EthAmount short compact value={row.marketCap} />)} />
								<Chip icon={<Tooltip title="Liquidity"><WaterDropIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={(<EthAmount short compact value={row.liquidity} />)} />
								<Chip icon={<Tooltip title="Current Price"><SellIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={(<EthAmount hideCurrency compact value={row.currentPrice} />)} />
								<Chip icon={<Tooltip title="Creator Token Holding"><GroupsIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={`${row.creatorTokenHolding}%`} />
								{chain === "sol" ? <Chip icon={<Tooltip title="Creator Token Sends"><SendIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={`${row.creatorTokenSends}%`} /> : <></>}
								{chain === "sol" ? <Chip icon={<Tooltip title="Largest Holder"><BackHandIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={`${row.biggestSingleHolder}%`} /> : <></>}
								{chain === "sol" ? <Chip icon={<Tooltip title="LP Supply"><ApiIcon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={`${row.liquiditySupplyPercentage}%`} /> : <></>}
								{chain === "eth" ? <Chip color={row.renounced ? "success" : "error"} icon={<Tooltip title="Ownership"><Person2Icon /></Tooltip>} style={{ marginBottom: "2px" }} size="small" label={`${row.renounced ? "RENOUNCED" : "OWNED"}`} /> : <></>}
								{chain === "sol" ? <Chip color={row.liquidityLocked ? "success" : "error"} icon={<Tooltip title="LP Token"><LockIcon /></Tooltip >} style={{ marginBottom: "2px" }} size="small" label={`${row.liquidityLocked ? "LOCKED" : "UNLOCKED"}`} /> : <></>}
								{chain === "sol" ? <Chip color={row.immutableMetadata ? "success" : "error"} icon={<Tooltip title="Metadata"><TaskIcon /></Tooltip >} style={{ marginBottom: "2px" }} size="small" label={`${row.immutableMetadata ? "IMMUTABLE" : "MUTABLE"}`} /> : <></>}
								{chain === "sol" ? <Chip color={row.mintingDisabled ? "success" : "error"} icon={<Tooltip title="Minting"><MonetizationOnIcon /></Tooltip >} style={{ marginBottom: "2px" }} size="small" label={`${row.mintingDisabled ? "DISABLED" : "ENABLED"}`} /> : <></>}
								{chain === "sol" ? <Chip color={row.freezeDisabled ? "success" : "error"} icon={<Tooltip title="Account Freeze"><AcUnitIcon /></Tooltip >} style={{ marginBottom: "2px" }} size="small" label={`${row.freezeDisabled ? "DISABLED" : "ENABLED"}`} /> : <></>}
								{chain === "eth" ? <Chip color={row.contractVerified ? "success" : "error"} icon={<Tooltip title="Contract Code"><VerifiedIcon /></Tooltip >} style={{ marginBottom: "2px" }} size="small" label={`${row.contractVerified ? "VERIFIED" : "UNVERIFIED"}`} /> : <></>}
							</Stack>
						</Stack>
						
						<Stack spacing={1} alignItems="center">
							<Avatar sx={{ bgcolor: getColor(row.score), width: "50px", height: "50px" }}>{row.score?.toFixed(1)}</Avatar>
								{chain === "sol" && new Date(row.createdAt) < new Date() ?
								<Chip sx={{
									marginTop: "2px !important",
									"& .MuiChip-label": { padding: 0 },
									"& .MuiChip-icon": { marginRight: "4px" },
									}} size="small" icon={
										<Tooltip title="Quick buy from Main wallet">
											<IconButton component="div" sx={{padding: 0}} onClick={(event) => { expand(event) }}>
												<ShoppingCartIcon fontSize="10px" />
											</IconButton>
										</Tooltip>} label={
										expanded ? (
										<Stack direction="row" spacing="2px" justifyContent="space-between">
											<IconButton sx={{padding: "1px"}} component="div" onClick={(event) => { quickBuy(event, row.metadata.address, 0.1) }} size="small"><Avatar sx={{ width: 24, height: 24, fontSize: "10px" }}>0.1</Avatar></IconButton>
											<IconButton sx={{ padding: "1px" }} component="div" onClick={(event) => { quickBuy(event, row.metadata.address, 0.5) }} size="small"><Avatar sx={{ width: 24, height: 24, fontSize: "10px" }}>0.5</Avatar></IconButton>
											<IconButton sx={{ padding: "1px" }} component="div" onClick={(event) => { quickBuy(event, row.metadata.address, 0.1) }} size="small"><Avatar sx={{ width: 24, height: 24, fontSize: "10px" }}>1</Avatar></IconButton>
										</Stack>)
									: <></>}
								/> : <></>}
						</Stack>
						</Stack>
					</CardActionArea>
				</Card>
			</Collapse>
		)
	}

	return (
		<>
			<Typography variant="h3">Latest Pools</Typography>
			<Box sx={{ width: '100%' }}>
				{poolEvents.length === 0 ?
					<Loading /> :
					<Stack sx={{ maxHeight: "375px", overflow: "auto" }}>
						<TransitionGroup>
							{poolEvents.map(buildRow)}
						</TransitionGroup>
					</Stack>
				}
			</Box>
		</>
	);
};
