import React from "react";
import { Collapse, Navbar, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import GridViewIcon from '@mui/icons-material/GridView';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GradeIcon from '@mui/icons-material/Grade';
import WalletIcon from '@mui/icons-material/Wallet';
import HighlightIcon from '@mui/icons-material/Highlight';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useFetchData } from "./FetchDataContext";

export const NavMenu = ({ isNavCollapsed, setIsNavCollapsed, alwaysExpanded, isAdmin }) => {
	const { chain } = useFetchData();

	const toggleNav = () => {
		setIsNavCollapsed(!isNavCollapsed);
	};

	const height = 20;

	return (
		<header>
			<Box sx={{ padding: "10px 0 0", width: "80px", height: "50px" }}>
				<MenuLogo width="100%" height="100%" />
			</Box>
			<Navbar container style={{ listStyleType: "none", flexWrap: "nowrap", }}>
				<Stack>
					<NavItem>
						<NavLink>
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<MenuIcon color="leftNavText" onClick={toggleNav} style={{ cursor: "pointer" }} />
							</Box>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink tag={Link} to="/dashboard" title="Dashboard">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<GridViewIcon color="leftNavText" />
							</Box>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink tag={Link} to="/trade" title="Trade">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<AutoGraphIcon color="leftNavText" />
								</Box>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink tag={Link} to="/snipe" title="Snipe">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<TrackChangesIcon color="leftNavText" />
							</Box>
						</NavLink>
					</NavItem>
					{chain !== "eth" ?
						<NavItem>
							<NavLink tag={Link} to="/shotgun" title="Shotgun">
								<Box sx={{ color: "leftNavText.main", height: { height } }}>
									<HighlightIcon color="leftNavText" />
								</Box>
							</NavLink>
						</NavItem>
						: <></>}
					<NavItem>
						<NavLink tag={Link} to="/copy-trades" title="Copy Trade">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<ContentCopyIcon color="leftNavText" />
							</Box>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink tag={Link} to="/referrals" title="Referrals">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<PeopleAltIcon color="leftNavText" />
							</Box>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink tag={Link} to="/rewards" title="Rewards">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<GradeIcon color="leftNavText" />
							</Box>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink tag={Link} to="/wallet" title="Wallets">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<WalletIcon color="leftNavText" />
							</Box>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="https://unodex.gitbook.io/docs" target="_blank" title="Help">
							<Box sx={{ color: "leftNavText.main", height: { height } }}>
								<HelpIcon color="leftNavText" />
							</Box>
						</NavLink>
					</NavItem>
					{isAdmin ?
						<NavItem>
							<NavLink tag={Link} to="/admin" title="Admin">
								<Box sx={{ color: "leftNavText.main", height: { height } }}>
									<AdminPanelSettingsIcon color="leftNavText" />
								</Box>
							</NavLink>
						</NavItem>
						: <></>
					}
				</Stack>
				<Stack>
					<Collapse
						isOpen={alwaysExpanded || !isNavCollapsed}
						navbar
						horizontal
					>
						<NavItem>
							<NavLink>
								<Box sx={{ color: "leftNavText.main", height: { height } }}>&nbsp;</Box>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/dashboard" onClick={toggleNav}>
								<Box sx={{ color: "leftNavText.main", height: { height } }}>Dashboard</Box>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/trade" onClick={toggleNav}>
								<Box sx={{ color: "leftNavText.main", height: { height } }}>Trades</Box>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/snipe" onClick={toggleNav}>
								<Box sx={{ color: "leftNavText.main", height: { height } }}>Snipe</Box>
							</NavLink>
						</NavItem>
						{chain !== "eth" ?
							<NavItem>
								<NavLink tag={Link} to="/shotgun" onClick={toggleNav}>
									<Box sx={{ color: "leftNavText.main", height: { height } }}>Shotgun</Box>
								</NavLink>
							</NavItem>
						: <></>}
						<NavItem>
							<NavLink tag={Link} to="/copy-trades" onClick={toggleNav}>
								<Box sx={{ color: "leftNavText.main", height: { height } }}>Copy&nbsp;Trades</Box>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/referrals" onClick={toggleNav}>
								<Box sx={{ color: "leftNavText.main", height: { height } }}>Referrals</Box>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/rewards" onClick={toggleNav}>
								<Box sx={{ color: "leftNavText.main", height: { height } }}>Claim&nbsp;Rewards</Box>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/wallet" onClick={toggleNav}>
								<Box sx={{ color: "leftNavText.main", height: {height} }}>Wallet</Box>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="https://unodex.gitbook.io/docs" target="_blank" title="Help">
								<Box sx={{ color: "leftNavText.main", height: { height } }}>Help</Box>
							</NavLink>
						</NavItem>
						{isAdmin ?
							<NavItem>
								<NavLink tag={Link} to="/admin" onClick={toggleNav}>
									<Box sx={{ color: "leftNavText.main", height: { height } }}>Admin</Box>
								</NavLink>
							</NavItem>
							: <></>}
					</Collapse>
				</Stack>
			</Navbar>
		</header>
	);
};

export const MenuLogo = () => {
	const rand = Math.random();

	return (
		<svg viewBox="0 0 5333.3335 3221.3333" width="80" height="40" xmlns="http://www.w3.org/2000/svg" version="1.1">
			<defs id="defs6">
				<linearGradient id={`linearGradient28-${rand}`} spreadMethod="pad" x1="0" x2="1" y1="0.5" y2="0.5">
					<stop id="stop22" offset="0" stopColor="#163aff" />
					<stop id="stop24" offset="0" stopColor="#163aff" />
					<stop id="stop26" offset="1" stopColor="#06e3f9" />
				</linearGradient>
				<linearGradient id={`linearGradient50-${rand}`} spreadMethod="pad" x1="0" x2="1" y1="0.5" y2="0.5">
					<stop id="stop44" offset="0" stopColor="#163aff" />
					<stop id="stop46" offset="0" stopColor="#163aff" />
					<stop id="stop48" offset="1" stopColor="#06e3f9" />
				</linearGradient>
				<clipPath clipPathUnits="userSpaceOnUse" id="clipPath60">
					<path d="m0,2416l4000,0l0,-2416l-4000,0l0,2416z" id="path58" />
				</clipPath>
			</defs>
			<g className="layer">
				<title>Layer 1</title>
				<g id="g8" transform="matrix(4.10601 0 0 -4.11282 -5545.35 6578.95)">
					<g id="g10">
						<g id="g12">
							<g id="g18">
								<g id="g20">
									<path d="m2146.39,1506.47l0,-169.01l-146.43,-84.58l-146.35,84.58l0,169.01l-109.71,63.31l0,-295.7l256.06,-147.88l256.14,147.88l0,295.7l-109.71,-63.31z" fill={`url(#linearGradient28-${rand})`} id="path30" stroke="#000000" />
								</g>
							</g>
						</g>
					</g>
					<g id="g32">
						<g id="g34">
							<g id="g40">
								<g id="g42">
									<path d="m2146.39,1506.47l-109.71,63.31l-36.72,21.2l-36.71,-21.2l-109.64,-63.31l146.35,-84.5l146.43,84.5z" fill={`url(#linearGradient50-${rand})`} id="path52" stroke="#000000" />
								</g>
							</g>
						</g>
					</g>
					<g id="g54">
						<g clipPath="url(#clipPath60)" id="g56">
							<g id="g62" transform="translate(1541.55 1039.39)">
								<path d="m0,0l0,-123.96c0,-27.26 -8.06,-49.09 -24.03,-65.65c-15.97,-16.56 -38.25,-24.76 -66.82,-24.76c-28.86,0 -51.14,8.2 -66.96,24.61c-15.83,16.42 -23.74,38.4 -23.74,65.8l0,123.96l41.76,0l0,-123.38c0,-15.97 4.25,-28.86 12.75,-38.53c8.5,-9.67 20.66,-14.51 36.34,-14.51c15.97,0 28.28,4.84 36.78,14.51c8.5,9.67 12.74,22.56 12.74,38.53l0,123.38l41.18,0z" fill="#FFFFFF" fillRule="nonzero" id="path64" stroke="#000000" />
							</g>
							<g id="g66" transform="translate(1759.57 1039.39)">
								<path d="m0,0l0,-210.12l-33.85,0l-105.65,136.56l0,-136.56l-41.17,0l0,210.12l33.99,0l105.51,-136.27l0,136.27l41.17,0z" fill="#FFFFFF" fillRule="nonzero" id="path68" stroke="#000000" />
							</g>
							<g id="g70" transform="translate(1899.93 862.971)">
								<path d="m0,0c20.37,0 36.92,6.89 49.67,20.51c12.75,13.63 19.05,30.63 19.05,51c0,20.07 -6.3,36.92 -19.05,50.55c-12.75,13.63 -29.3,20.37 -49.67,20.37c-20.37,0 -36.92,-6.74 -49.67,-20.37c-12.75,-13.63 -19.05,-30.48 -19.05,-50.55c0,-20.37 6.3,-37.37 19.05,-51c12.75,-13.62 29.3,-20.36 49.67,-20.36l0,-0.15zm0.15,-37.95c-31.65,0 -57.88,10.4 -78.84,31.36c-20.95,20.95 -31.5,47.03 -31.5,78.24c0,30.92 10.55,56.86 31.5,77.66c20.96,20.81 47.33,31.21 78.98,31.21c31.65,0 57.88,-10.4 78.84,-31.21c20.95,-20.8 31.35,-46.74 31.35,-77.66c0,-31.21 -10.4,-57.29 -31.35,-78.24c-20.96,-20.96 -47.19,-31.36 -78.84,-31.36l-0.14,0z" fill="#FFFFFF" fillRule="nonzero" id="path72" stroke="#000000" />
							</g>
							<g id="g74" transform="translate(2130.69 866.487)">
								<path d="m0,0c18.02,0 32.53,6.3 43.52,19.05c10.99,12.75 16.56,29.31 16.56,49.67c0,20.08 -5.42,36.34 -16.41,48.8c-10.99,12.45 -25.5,18.61 -43.52,18.61l-49.53,0l0,-135.98l49.53,0l-0.15,-0.15zm1.02,173.05c30.34,0 54.66,-9.67 72.98,-29.16c18.31,-19.49 27.55,-44.84 27.55,-76.05c0,-30.77 -9.09,-55.97 -27.4,-75.61c-18.32,-19.63 -42.65,-29.45 -72.98,-29.45l-91.28,0l0,210.12l91.28,0l-0.15,0.15z" fill="#FFFFFF" fillRule="nonzero" id="path76" stroke="#000000" />
							</g>
							<g id="g78" transform="translate(2302.85 1002.17)">
								<path d="m0,0l0,-48.06l99.2,0l0,-36.05l-99.2,0l0,-51.58l114.29,0l0,-37.21l-154.58,0l0,210.12l154.88,0l0,-37.22l-114.59,0z" fill="#FFFFFF" fillRule="nonzero" id="path80" stroke="#000000" />
							</g>
							<g id="g82" transform="translate(2590.62 829.269)">
								<path d="m0,0l-53.48,74.73l-53.49,-74.73l-49.81,0l75.75,105.65l-73.85,104.47l49.82,0l51.58,-73.26l51.58,73.26l49.52,0l-73.56,-104.47l75.32,-105.65l-49.38,0z" fill="#FFFFFF" fillRule="nonzero" id="path84" stroke="#000000" />
							</g>
						</g>
					</g>
				</g>
				<g id="Layer">
					<g id="Rectangle arrondi 1">
						<g id="Rectangle arrondi 1">
							<g id="&lt;Group&gt;">
								<path id="&lt;Path&gt;" style={{ fill: "#f52020"}} d="m2986.3 1450.2c0-27.6 22.4-50 50-50h899.6c27.6 0 50 22.4 50 50v240.6c0 27.6-22.4 50-50 50h-899.6c-27.6 0-50-22.4-50-50z" />
							</g>
							<g id="&lt;Group&gt;">
								<path id="&lt;Path&gt;" fillRule="evenodd" style={{ fill: "none", stroke: "#f52020", strokeMiterlimit: 100, strokeWidth: 15 }} d="m2986.3 1450.2c0-27.6 22.4-50 50-50h899.6c27.6 0 50 22.4 50 50v240.6c0 27.6-22.4 50-50 50h-899.6c-27.6 0-50-22.4-50-50z" />
							</g>
						</g>
					</g>
					<g id="BETA">
						<path id="BETA " fillRule="evenodd" style={{ fill: "#ffffff" }} d="m3195.2 1680.1h-103.3v-221.8h99.9c44.9 0 71.8 22.2 71.8 57.6 0 26.9-16.2 44.2-37.7 50.4 26.1 5.4 42.7 28 42.7 53.4 0 36.5-26.9 60.4-73.4 60.4zm-13.8-178.4h-35.3v46.1h35.3c17.6 0 27.3-7.7 27.3-23 0-15-9.7-23.1-27.3-23.1zm3.8 85.7h-39.1v49.2h39.5c18.1 0 28.4-8.4 28.4-24.2 0-15.8-11.1-25-28.8-25zm266.2-85.7h-81.8v44.6h72.2v41.9h-72.2v48.4h81.8v43.5h-135.6v-221.8h135.6zm38.7-43.5h171.3v43.5h-58.4v178.3h-54.1v-178.3h-58.8zm352.2 182.6h-82.6l-13 39.2h-56.9l80.3-221.8h62.6l80.3 221.8h-57.3zm-41.4-123l-26.9 81.5h54.5z" />
					</g>
				</g>
			</g>
		</svg>
	);
}