import React from "react";
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { parseTransactions, Loading, ChainIcon } from "./shared/FormattingComponents"
import { v4 as uuidv4 } from 'uuid';

export const Notifications =({notifications, lastViewed}) => {



	return (
		<TableContainer>
			<Table size="small">
				<TableBody>
					{!notifications || notifications.length == 0 ?
						(
							<TableRow>
								<TableCell colSpan={2} align="left">
									<Loading />
								</TableCell>
							</TableRow>
						) :
						(notifications ?? []).map((row) => (
							<TableRow
								key={row.id == 0 ? uuidv4() : row.id }
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell sx={{ padding: "6px"}}>
									<ChainIcon value={row.chain} />
								</TableCell>
								<TableCell align="left" sx={{ paddingLeft: 0}}>
									{parseTransactions(row.message.substring(0, 250), null, row.chain)}
								</TableCell>
							</TableRow>
						))
					}
				</TableBody>
			</Table>
		</TableContainer>

	);
};
