import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PageSection } from './shared/PageSection'
import { Usage } from './admin/Usage'
import { Feed } from './admin/Feed'
import { UsersOverview } from './admin/UsersOverview'
import { UserLookup } from './admin/UserLookup'
 
export const Admin = () => {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Typography variant="h1">Admin</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} md={12} lg={6}>
					<PageSection>
						<Feed />
					</PageSection>
				</Grid>
				<Grid item xs={12} md={12} lg={6}>
					<PageSection>
						<UserLookup />
					</PageSection>
				</Grid>
				<Grid item xs={12} md={12} lg={12}>
					<PageSection>
						<Usage />
					</PageSection>
				</Grid>
				<Grid item xs={12} md={12} lg={6}>
					<PageSection>
						<UsersOverview />
					</PageSection>
				</Grid>
			</Grid>
		</Box>
	);
};
