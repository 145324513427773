import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { StyledTableCell } from '../shared/StyledTable'
import { ShortDecimal, DateTime, Token, ComingSoon } from '../shared/FormattingComponents'

export const TradeHistory = ({ }) => {

	const tradeHistoryData = [
		{ id: 1, lastModified: new Date("12/12/2023"), profit: "$34.23", token: { name: "x", symbol: "x", address: "x" } },
		{ id: 2, lastModified: new Date("12/12/2023"), profit: "$34.23", token: { name: "x", symbol: "x", address: "x" } },
		{ id: 3, lastModified: new Date("12/12/2023"), profit: "$34.23", token: { name: "x", symbol: "x", address: "x" } }
	];

	return (
		<>
			<ComingSoon />
			<div className="tradeHistoryContainer">
				<Typography variant="h3">Trade History</Typography>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								<StyledTableCell>Date</StyledTableCell>
								<StyledTableCell align="center">From</StyledTableCell>
								<StyledTableCell align="center">To</StyledTableCell>
								<StyledTableCell align="right">Profit</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tradeHistoryData.map((row) => (
								<TableRow
									key={row.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row"><DateTime value={row.lastModified} /></TableCell>
									<TableCell align="center"><Token value={row.token} /></TableCell>
									<TableCell align="center"><Token value={row.token} /></TableCell>
									<TableCell align="right"><ShortDecimal value={row.profit} /></TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
}
