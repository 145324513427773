import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell } from '../shared/StyledTable'
import Typography from '@mui/material/Typography';

export const ReferralRewardHistory = ({ rewardHistory }) => {
	return (
		<>
			<Typography variant="h3">Referral Reward History</Typography>
			<Box sx={{ width: '100%' }}>			
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								<StyledTableCell>#</StyledTableCell>
								<StyledTableCell align="center">Date</StyledTableCell>
								<StyledTableCell align="center">Wallet</StyledTableCell>
								<StyledTableCell align="center">Swap Cost/Gain (ETH)</StyledTableCell>
								<StyledTableCell align="center">Referral Reward (ETH)</StyledTableCell>
								<StyledTableCell align="center">Transactions</StyledTableCell>
								<StyledTableCell align="center">Status</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rewardHistory?.map((row) => (
								<TableRow
									key={row.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row"></TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center"></TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{rewardHistory?.length > 0 ? (
					<></>
				) : (
					<p align="center">No referral data available</p>
				)}
			</Box>
		</>
	);
};