import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Loading } from '../shared/FormattingComponents';
import { useFetchData } from "../FetchDataContext";
import { BarChart } from '@mui/x-charts/BarChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';

export const Usage = () => {
	const { fetchData } = useFetchData();
	const [loading, setLoading] = React.useState(false);
	const [usageData, setUsageData] = React.useState(null);

	React.useEffect(() => {
		const getUsageData = async () => {
			try {
				const response = await fetchData(`/api/admin/usage`, "get");
				setUsageData(response);
				setLoading(false);
			} catch (err) {
				setUsageData(null);
			}
		}

		if (loading) {
			getUsageData();
		}
	}, [loading]);

	return (
		<>
			<Typography variant="h3">Usage Data</Typography>
			{loading ?
				<Loading /> :
				usageData == null ?
					<Button onClick={() => { setLoading(true); }}>Load Data</Button> :
					<Box sx={{ width: '100%', height: "500px" }}>
						<BarChart
							slotProps={{ legend: { hidden: true } }}
							tooltip={{ trigger: 'item' }}
							xAxis={[{
								tickLabelStyle: {
									angle: 90,
									textAnchor: 'start',
									fontSize: 12,
								}, scaleType: 'band', data: usageData.xAxis.map(x => { return new Date(x) }) }]}
							series={usageData.datum}
						/>
					</Box>
			}
		</>
	);

}