import React, { useState, useEffect } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import WalletIcon from '@mui/icons-material/Wallet';
import { useWallets } from "../WalletContext"
import { Loading } from "../shared/FormattingComponents"

export const WalletToggle = ({ value, onChange, allowAll = true }) => {
	const { loading, error, loadWallet, walletCount, linkedWallet, wallet1, wallet2, wallet3, wallet4, wallet5 } = useWallets();

	if (loading) {
		return <Box sx={{position: "relative", height: "48px"}}><Loading /></Box>;
	}

	if (error) {
		return <div>Error fetching wallets: {error.message}</div>;
	}

	const handleChange = (event, newValue) => {
		if (newValue !== null) {
			const wallet = newValue === 0 ? null : newValue === 1 ? wallet1 : newValue === 2 ? wallet2 : newValue === 3 ? wallet3 : newValue === 4 ? wallet4 : wallet5;
			onChange(newValue, wallet);
		}
	};

	return (
		<>
			<ToggleButtonGroup
				value={value}
				exclusive
				color="primary"
				onChange={handleChange}
				aria-label="Wallet"
				fullWidth
			>
				<ToggleButton key="na" value={-1} disabled>
					<WalletIcon />
				</ToggleButton>
				{allowAll ? (
					<ToggleButton key={0} value={0} aria-label="All wallets">
						All
					</ToggleButton>
				) : ([])}
				<ToggleButton key={1}  value={1} aria-label="Main wallet">
					Main
				</ToggleButton>
				{walletCount >= 2 ?
					<ToggleButton key={2} value={2} aria-label="Wallet 2">
						2
					</ToggleButton> : []
				}
				{walletCount >= 3 ?
					<ToggleButton key={3} value={3} aria-label="Wallet 3">
						3
					</ToggleButton> : []
				}
				{walletCount >= 4 ?
					<ToggleButton key={4} value={4} aria-label="Wallet 4">
						4
					</ToggleButton> : []
				}
				{walletCount >= 5 ?
					<ToggleButton key={5} value={5} aria-label="Wallet 5">
						5
					</ToggleButton> : []
				}
			</ToggleButtonGroup>
		</>
	);
}
