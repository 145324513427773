import React, { useState, useRef } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { CopyToClipboard } from '../shared/CopyToClipboard'

export const ReferralLink = ({ referralData }) => {

	return (
		<Stack sx={{ width: "100%" }}>
				<TextField label="Your referral URL" variant="outlined" size="small" margin="dense" value={referralData.data?.referralUrl} readOnly InputProps={{
					endAdornment: <InputAdornment position="end"><CopyToClipboard text={referralData.data?.referralUrl} /></InputAdornment>
				}} />
				<p>
					Sharing the referral link allows you to earn 25% of the trading fees
					generated by your referred user.
				</p>
				<p>Rewards data updates every 24h.</p>
		</Stack>
	);
};