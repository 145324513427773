import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TabPanel } from '../shared/TabPanel'
import { StyledTableCell } from '../shared/StyledTable'
import TableFooter from '@mui/material/TableFooter';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import { useFetchData } from "../FetchDataContext";
import { ShortAddress } from "../shared/FormattingComponents";
import { EthAmount } from "../shared/EthAmount";
import Typography from '@mui/material/Typography';

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const BestTraders = () => {
	const { topTradersData } = useFetchData();
	const [activeTab, setActiveTab] = React.useState(0);

	const [dayPage, setDayPage] = React.useState(0);
	const handleDayChangePage = (event, newPage) => {
		setDayPage(newPage);
	};

	const [weekPage, setWeekPage] = React.useState(0);
	const handleWeekChangePage = (event, newPage) => {
		setWeekPage(newPage);
	};

	const rowsPerPage = 5;

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	return (
		<>
			<Typography variant="h3">Best Traders</Typography>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={activeTab} onChange={handleTabChange}  aria-label="basic tabs example">
						<Tab label="24h" {...a11yProps(0)} />
						<Tab label="7d" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<TabPanel value={activeTab} index={0}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell>#</StyledTableCell>
									<StyledTableCell align="center">Trader</StyledTableCell>
									<StyledTableCell align="center">Volume</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{topTradersData.loading ?
									<TableRow>
										<TableCell colSpan={3} align="center"><CircularProgress size={20} /></TableCell>
									</TableRow> : (
										topTradersData.error != null || topTradersData.data.oneDayTop.length === 0 ?
										<TableRow>
											<TableCell colSpan={3} align="center">{topTradersData.error ? "Error retrieving data" : "No Data"}</TableCell>
										</TableRow> : 

										topTradersData.data.oneDayTop.slice(dayPage * rowsPerPage, dayPage * rowsPerPage + rowsPerPage).map((row, index) => (
											<TableRow
												key={`oneDay_${row.userID}`}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row">{index + 1}</TableCell>
												<TableCell align="center"><ShortAddress value={row.userID} /></TableCell>
												<TableCell align="center"><EthAmount value={row.volume} /></TableCell>
											</TableRow>
										))
									)}
							</TableBody>
							{topTradersData.loading === false && topTradersData.error === null ?
								<TableFooter>
									<TableRow>
										<TablePagination
											colSpan={3}
											count={topTradersData.data?.oneDayTop?.length}
											rowsPerPage={rowsPerPage}
											rowsPerPageOptions={[]}
											page={dayPage}
											onPageChange={handleDayChangePage}
											showFirstButton
											showLastButton
											sx={{
												'& .MuiToolbar-root': { justifyContent: "space-between" },
												'& .MuiTablePagination-displayedRows': { margin: "auto 0" },
												'& .MuiTablePagination-spacer': { display: "none" }
											}}
										/>
									</TableRow>
								</TableFooter>
							: <></>}
						</Table>
					</TableContainer>
				</TabPanel>
				<TabPanel value={activeTab} index={1}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell>#</StyledTableCell>
									<StyledTableCell align="center">Trader</StyledTableCell>
									<StyledTableCell align="center">Volume</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{topTradersData.loading ?
									<TableRow>
										<TableCell colSpan={3} align="center"><CircularProgress size={20} /></TableCell>
									</TableRow> : (
										topTradersData.error != null || topTradersData.data.oneWeekTop.length === 0 ?
											<TableRow>
												<TableCell colSpan={3} align="center">{topTradersData.error ? "Error retrieving data" : "No Data"}</TableCell>
											</TableRow> :						
											topTradersData.data.oneWeekTop.slice(weekPage * rowsPerPage, weekPage * rowsPerPage + rowsPerPage).map((row, index) => (
												<TableRow
													key={`oneWeek_${row.userID}`}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">{index + 1}</TableCell>
													<TableCell align="center"><ShortAddress value={row.userID} /></TableCell>
													<TableCell align="center"><EthAmount value={row.volume} /></TableCell>
												</TableRow>
											))								
									)}
							</TableBody>
							{topTradersData.loading === false && topTradersData.error === null ?
								<TableFooter>
									<TableRow>
										<TablePagination
											colSpan={3}
											count={topTradersData.data.oneWeekTop.length}
											rowsPerPage={rowsPerPage}
											rowsPerPageOptions={[]}
											page={weekPage}
											onPageChange={handleWeekChangePage}
											showFirstButton
											showLastButton
											sx={{
												'& .MuiToolbar-root': { justifyContent: "space-between" },
												'& .MuiTablePagination-displayedRows': { margin: "auto 0" },
												'& .MuiTablePagination-spacer': { display: "none" }
											}}
										/>
									</TableRow>
								</TableFooter> : <></>}
						</Table>
					</TableContainer>
				</TabPanel>
			</Box>
		</>
	);
};
