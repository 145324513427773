import React from "react";
import { useContractWrite, usePrepareContractWrite } from 'wagmi'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EthAmount } from "../shared/EthAmount";

export const ClaimRewardsTile = ({ unclaimedRewards }) => {
	const { config } = usePrepareContractWrite({
		address: '0x4dc41A6777C0Dd256Af68C5DD7a875D070A6e4D4',
		abi: [ { "inputs": [], "name": "claimRewards", "outputs": [], "stateMutability": "nonpayable", "type": "function" } ],
		functionName: 'claimRewards',
	})
	const { data, isLoading, isSuccess, write } = useContractWrite(config)

	return (
		<Stack spacing={2}>
			<Typography variant="h3">Claim Rewards</Typography>
			<EthAmount fontSize="normal" value={unclaimedRewards}/>
			<Button variant="contained" disabled={!write || unclaimedRewards === 0} onClick={() => write?.()}>
				Claim
			</Button>
		</Stack>
	);
};
