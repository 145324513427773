import React, { useState, useEffect } from "react";
import '../style/copyToClipboard.css';
import { useDarkMode } from '../DarkModeContext';

export const CopyToClipboard = ({ text }) => {
	const [copied, setCopied] = useState(false);
	const { darkMode } = useDarkMode();

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (copied) setCopied(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [copied]);

	const handleCopy = async (event) => {
		event.stopPropagation();
		await navigator.clipboard.writeText(text);
		setCopied(true);
	}

	return (
		<div className="copyToClipboard">
			<button onClick={handleCopy}>
				<div>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						fillOpacity="0"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						style={{
							color: (darkMode ? "lightgray" : "darkgray"),
							position: "absolute",
							top: 0,
							left: 0,
							strokeDasharray: 50,
							strokeDashoffset: copied ? -50 : 0,
							transition: "all 300ms ease-in-out"
						}}>
						<path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
						<path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
					</svg>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						fillOpacity="0"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
						style={{
							color: (darkMode ? "lightgreen" : "darkgreen"),
							position: "absolute",
							top: 0,
							left: 0,
							strokeDasharray: 50,
							strokeDashoffset: copied ? 0 : -50,
							transition: "all 300ms ease-in-out"
						}}>
						<path d="M13.25 4.75L6 12L2.75 8.75" />
					</svg>
				</div>
			</button>
		</div>
	);
}
