import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from "react";

export const TransactionsTile = ({ referrerTransactions }) => {
	return (
		<Box>
			<Typography variant="h3">Transactions</Typography>
			<Typography>{referrerTransactions}</Typography>
		</Box>
	);
};
