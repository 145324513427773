import React from "react";
import Table from '@mui/material/Table';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledTableCell } from '../shared/StyledTable';
import { Token, TokenLogo } from "../shared/FormattingComponents";
import { useFetchData } from "../FetchDataContext";
import { EthAmount } from "../shared/EthAmount";
import Typography from '@mui/material/Typography';

export const TopTradedTokens = ({ setSelectedToken }) => {
	const { topTradedTokenData, chain } = useFetchData();

	const rowsPerPage = 5;
	const [page, setPage] = React.useState(0);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleRowClick = (row) => {
		if (setSelectedToken) {
			setSelectedToken(row);
		}
	}

	return (
		<>
			<Typography variant="h3" mb={2}>Top Traded Tokens 24h</Typography>
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							<StyledTableCell>Token</StyledTableCell>
							<StyledTableCell align="right">Price</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{topTradedTokenData.loading ?
							<TableRow>
								<TableCell colSpan={2} align="center"><CircularProgress size={20} /></TableCell>
							</TableRow> :
							topTradedTokenData.data.length === 0 ?
								<TableRow>
									<TableCell colSpan={2} align="center">{topTradedTokenData.error ? "Error retrieving data" : "No Data"}</TableCell>
								</TableRow> : (
							topTradedTokenData.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
								<TableRow
									key={row.address}
									sx={{ cursor: "pointer", '&:last-child td, &:last-child th': { border: 0 } }}
									hover
									onClick={() => handleRowClick(row)}>
									<TableCell component="th" scope="row">
										<Stack direction="row" alignItems="center" spacing={1}>
											<TokenLogo token={row} size={30} />
											<Box>{row.name}</Box>
											<Token value={row}></Token>
										</Stack>
									</TableCell>
									<TableCell align="right"><EthAmount value={row.price?.nativePrice} /></TableCell>
								</TableRow>
							))
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={2}
								count={topTradedTokenData.data.length}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={[]}
								page={page}
								onPageChange={handleChangePage}
								showFirstButton
								showLastButton
								sx={{
									'& .MuiToolbar-root': { justifyContent: "space-between" },
									'& .MuiTablePagination-displayedRows': { margin: "auto 0" },
									'& .MuiTablePagination-spacer': { display: "none" }
								}}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
};