import React, { createContext, useContext } from 'react';
import EthIcon from '../img/eth.png'
import SolIcon from '../img/sol.png'
import BscIcon from '../img/bsc.svg'
import { useFetchData } from "./FetchDataContext";
const PriceContext = createContext();

const Eth = { name: "Ethereum", symbol: "ETH", address: "NATIVE", decimals: 18, logo: EthIcon, wrapped: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", gasUnit: "Gwei"  };
const Sol = { name: "Solana", symbol: "SOL", address: "NATIVE", decimals: 9, logo: SolIcon, wrapped: "So11111111111111111111111111111111111111112", gasUnit: "ML" }
const Bsc = { name: "Binance Coin", symbol: "BNB", address: "NATIVE", decimals: 18, logo: BscIcon, wrapped: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", gasUnit: "Gwei" }

export const PriceProvider = ({ children }) => {
	const { fetchData, chain } = useFetchData();

	const [gasPrice, setGasPrice] = React.useState(null);
	const [native, setNative] = React.useState(Eth);
	const [ethPrice, setEthPrice] = React.useState(0);
	const [nativePrice, setNativePrice] = React.useState(0);
	const [latestPriority, setLatestPriority] = React.useState(null);

	const nativeToUsd = (value) => {
		return nativePrice * parseFloat(value);
	};

	const usdToNative = (value) => {
		return parseFloat(value) / nativePrice;
	};

	React.useEffect(() => {
		switch (chain) {
			case "sol":
				setNative(Sol);
				break;
			case "bsc":
				setNative(Bsc);
				break;
			default:
				setNative(Eth);
				break;
		}
	}, [chain]);

	React.useEffect(() => {
		const getPrices = async () => {
			const data = await fetchData("/api/chain");
			if (data.gasPrice) {
				setGasPrice(data.gasPrice);
			}
			if (data.priorityFees) {
				setLatestPriority(data.priorityFees ?? data);
			}
			if (data.ethPrice) {
				setEthPrice(data.ethPrice);
			}
			if (data.nativePrice) {
				setNativePrice(data.nativePrice);
			}
		}

		getPrices();

		const intervalCall = setInterval(() => {
			getPrices();
		}, 15000);

		return () => {
			clearInterval(intervalCall);
		};
	}, [fetchData, chain]);

	return (
		<PriceContext.Provider value={{ gasPrice, ethPrice, nativePrice, latestPriority, native, nativeToUsd, usdToNative }}>
			{children}
		</PriceContext.Provider>
	);
};

export const usePrice = () => {
	return useContext(PriceContext);
};
