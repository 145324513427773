import React, { Component } from 'react';
import Box from '@mui/material/Box';

export class CopyTradesCard extends Component {
  static displayName = CopyTradesCard.name;

  render() {
    return (
		<Box sx={{ height: "80px"}}></Box>
    );
  }
}
