import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const TotalReferrals = ({ totalReferrals }) => {
 
  return (
    <Box>
		<Typography variant="h3">Total Referrals</Typography>
		<Typography>{totalReferrals}</Typography>
    </Box>
  );
};
