import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ComingSoon } from '../shared/FormattingComponents';
import '../style/profitableTotal.css'

export const ProfitableTotal = ({ dashboardData }) => {
	const totalProfitableTrades = dashboardData?.totalProfitableTrades ?? 0;
	const percentage = dashboardData?.totalTrades
		? ((totalProfitableTrades / dashboardData?.totalTrades) * 100).toFixed(2)
		: "0";

	const maxProfitablePercentage = 100; // Max percentage
	const degreePerData = 180 / maxProfitablePercentage;
	const calculateRotation = (percentage) => {
		const safePercentage = Math.min(percentage, maxProfitablePercentage);
		return -90 + safePercentage * degreePerData;
	};

	const needleStyle = {
		transform: `rotate(${calculateRotation(percentage)}deg)`,
	};

	return (
		<>
			<ComingSoon />
			<Stack justifyContent="space-evenly" alignItems="center" xs={{ width: "100%" }} spacing={2}>
				<Typography variant="h3">Profitable Total</Typography>
				<div className="gaugeContainer">
					<div className="gauge">
						<div className="progress">
							<div className="bar"></div>
							<div className="needle" style={needleStyle}></div>
						</div>
						<div className="gauge-data">{percentage}%</div>{" "}
					</div>
				</div>
			</Stack>
		</>
	);
};
