import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const TotalRewardsTile = ({ totalRewards }) => {
  return (
    <Box>
      <Typography variant="h3">Total Rewards</Typography>
      <Typography>{totalRewards}</Typography>
    </Box>
  );
};
