

export const getFieldColor = (valid) => { 
	return valid ? "primary" : "error";
}

export const requiredValidDecimal = (input) => {
	return required(input) && validDecimal(input) && parseFloat(input) > 0;
}

export const validDecimal = (input) => {
	return (input ?? "").toString().trim().match(/[^0-9.e\-]/g) == null;
}

export const required = (input) => {
	if (input == null || input.toString().trim() == "") {
		return false;
	}

	return true;
}