import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { parseTransactions } from "../shared/FormattingComponents"
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useFetchData } from "../FetchDataContext";

export const FunctionLogs = ({ idToShow, setIdToShow }) => {
	const { fetchData } = useFetchData();

	const [open, setOpen] = React.useState(false);
	const [data, setData] = React.useState(null);

	React.useEffect(() => {
		if (idToShow) {
			fetchData(`/api/function/${idToShow}`).then((response) => {
				setData(response);
				setOpen(true);
			}).catch(err => {
			});
		} else {
			setData(null);
			setOpen(false);
		}
	}, [idToShow]);


	const handleClose = () => {
		setIdToShow(null);
	};

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={handleClose}
			>
				<DialogTitle id="alert-dialog-title">
					{"Logs"}
				</DialogTitle>
				<DialogContent>
					{data?.logs.map(x => <pre key={x.id}>{parseTransactions(x.message, data.token, data?.chain)}</pre>) }
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}