import React from "react";
import { ShortAddress, Loading } from "../shared/FormattingComponents";
import { WalletBalance } from "../shared/WalletBalance";
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import LockIcon from '@mui/icons-material/Lock';
import { toast } from "react-toastify";
import { useFetchData } from "../FetchDataContext"
import { useWallets } from "../WalletContext"
import { TokenAmount } from "../shared/TokenAmount"
import { useSendTransaction, useContractWrite } from 'wagmi'
import { parseEther, parseUnits, formatUnits } from 'viem'
import { usePrice } from "../PriceContext";
import Typography from '@mui/material/Typography';

const filterOptions = createFilterOptions({
	stringify: (option) => `${option.name} ${option.address}`,
	limit: 30
});

const showToast = (message, type = "success") => {
	toast[type](message, {
		position: "bottom-right",
	});
};

export const WalletCard = ({ wallet, setWalletToShow, setSelectedToken }) => {
	const { fetchData, chain } = useFetchData();
	const { native } = usePrice();
	const { loading, error, loadWallet, walletCount, linkedWallet, wallet1, wallet2, wallet3, wallet4, wallet5 } = useWallets();

	const [token, setToken] = React.useState("NATIVE");
	const [tokenAmount, setTokenAmount] = React.useState(0);
	const [targetAddress, setTargetAddress] = React.useState(null);
	const [submitting, setSubmitting] = React.useState(false);
	const [walletOptions, setWalletOptions] = React.useState([]);

	const [amountError, setAmountError] = React.useState({ valid: 0, message: null });

	const { sendTransaction } = useSendTransaction({
		to: targetAddress ?? "",
		value: parseEther(tokenAmount.toString() ?? "0"),
		onSuccess: () => {
		}
	})

	const { write } = useContractWrite({
		abi: [{ "inputs": [{ "internalType": "address", "type": "address" }, { "internalType": "uint256", "type": "uint256" }], "name": "transfer", "outputs": [], "stateMutability": "nonpayable", "type": "function" }],
		functionName: 'transfer',
		address: token.address
	})

	React.useEffect(() => {
		const balance = formatUnits(wallet.nativeBalance ?? 0, native.decimals);
		if (token === "NATIVE") {
			if (tokenAmount > 0 && tokenAmount > balance) {
				// error - don't have this much
				setAmountError({ valid: 2, message: `Insufficient ${native.symbol} balance.` });
			} else if (tokenAmount > 0 && tokenAmount === balance) {
				setAmountError({ valid: 2, message: `Insufficient ${native.symbol} to pay for gas.` });
			} else if (tokenAmount > 0 && tokenAmount > balance - 0.001) {
				setAmountError({ valid: 1, message: `You may have insufficient ${native.symbol} to pay for gas.` });
			} else {
				setAmountError({ valid: 0, message: null });
			}
		} else {
			const tokenBalance = formatUnits(token.balance ?? 0, token.decimals);
			if (tokenAmount > tokenBalance) {
				setAmountError({ valid: 2, message: `Insufficient ${token.symbol} balance.` });
			} else if (balance < 0.001) {
				setAmountError({ valid: 1, message: `You may have insufficient ${native.symbol} to pay for gas.` });
			} else {
				setAmountError({ valid: 0, message: null });
			}
		}
	}, [token, tokenAmount]);

	React.useEffect(() => {
		const newOptions = [];
		if (chain !== "sol") newOptions.push(linkedWallet)
		newOptions.push(wallet1);
		if (walletCount >= 2)  newOptions.push(wallet2);
		if (walletCount >= 3)  newOptions.push(wallet3);
		if (walletCount >= 4)  newOptions.push(wallet4);
		if (walletCount >= 5)  newOptions.push(wallet5);

		const mapping = newOptions.map((x, index) => {
			return {
				value: index - 1,
				address: x.address,
				name: chain !== "sol" ? index === 0 ? "Linked Wallet" : index === 1 ? "Main Wallet" : `Wallet ${index}` : index === 0 ? "Main Wallet" : `Wallet ${index + 1}`
			}
		});
		if (chain !== "sol") {
			mapping.splice(wallet.index + 1, 1);
		} else {
			mapping.splice(wallet.index, 1);
		}
		setWalletOptions(mapping);

	}, [walletCount]);

	const handleSetToken = (evt) => {
		setToken(evt.target.value);
	}

	const handleSetTargetAddress = (evt, value) => {
		setTargetAddress(value.address);
	}

	const handleSetTokenAmount = (evt) => {
		if (token === "NATIVE") {
			setTokenAmount(evt.target.value);

		} else {
			setTokenAmount(evt.target.value);
		}
	}

	const handleSetValue = (v) => {
		if (token === "NATIVE") {
			setTokenAmount(v.toString());
		} else {
			setTokenAmount(v.toString());
		}
	}

	const handleFormSubmit = async () => {
		try {
			setSubmitting(true);

			if (wallet.index < 0) {
				if (token.address) {
					await write?.({
						args: [targetAddress ?? "", tokenAmount ? parseUnits(tokenAmount, token.decimals) : 0]
					})
				} else {
					await sendTransaction?.();
				}
			} else {
				const formDataToSubmit = {
					wallet: wallet.index,
					amount: tokenAmount,
					address: targetAddress,
					token: token.address
				};

				const response = await fetchData(
					`/api/wallet/${(token.address ? "token" : "native")}/send`,
					"post",
					formDataToSubmit
				);
			}
		} catch (err) {
			showToast(
				`Error sending tokens: ${err.response.data?.detail}`,
				"error"
			);
		} finally {
			setSubmitting(false);
		}
	}

	return (wallet.loading ? <Loading /> : 
			<Stack justifyContent="space-between" sx={{ height: "100%" }}>
				<Stack spacing={1} >
					<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
						<Stack direction="row" alignItems="center" spacing={1}>
							{wallet.index >= 0 ?
								<Tooltip title="Export private key">
									<IconButton onClick={() => { setWalletToShow(wallet.index) }}>
										<LockIcon sx={{ fontSize: "15px" }} />
									</IconButton>
								</Tooltip> :
								<></>
							}
							<Typography variant="h3">
								{wallet.index === -1 ? "Linked Wallet"
									: wallet.index === 0 ? `Main Wallet`
									: `Wallet ${wallet.index + 1}`}
							</Typography>
						</Stack>
						<ShortAddress value={wallet.address}></ShortAddress>
					</Stack>
					<Divider />
					<WalletBalance wallet={wallet} showFinancial={false} setSelectedToken={setSelectedToken} />
				</Stack>
				<Stack spacing={1}>

					<Divider />
					<Typography variant="h3">Transfer</Typography>
					<Stack direction="row" spacing={1}>
					<TokenAmount label="Send" onChange={handleSetTokenAmount} error={amountError} setValue={handleSetValue} value={tokenAmount} max={token === "NATIVE" ? formatUnits(wallet.nativeBalance ?? 0, native.decimals) : formatUnits(token.balance ?? 0, token.decimals)} />
						<Select
							value={token}
							label="Select token"
							sx={{ width: "35%"}}
							onChange={handleSetToken}
						>
							<MenuItem value="NATIVE">{ native.symbol}</MenuItem>
							{wallet.tokenBalance.map((row) => (
								<MenuItem key={row.address} value={row}>{row.symbol}</MenuItem>
							))}
						</Select>
					</Stack>
					<Autocomplete
						options={walletOptions}
						value={targetAddress}
						onChange={handleSetTargetAddress}
						getOptionLabel={(option) => option.name ? `${option.name} - (${option.address.substring(0, 6)}...${option.address.substring(38)})` : option.address ?? option}
						isOptionEqualToValue={(option, value) => { return option.address === value || option.address === value?.address }}
						renderInput={(params) => <TextField {...params} label="To wallet or address" />}
						renderOption={(props, option) => (
							<Box component="li" width="100%" {...props}>
								<Stack direction="row" width="100%" justifyContent="space-between">
									<Box>{option.name ?? option.address}</Box>
									{option.name ? <ShortAddress value={option.address} /> : <></>}
								</Stack>
							</Box>
						)}
						filterOptions={(options, params) => {
							const filtered = filterOptions(options, params);

							const { inputValue } = params;
							if ((inputValue.length === 42 && (chain === "eth" || chain === "bsc")) || (inputValue.length === 44 && chain === "sol")) {
								const isExisting = options.some((option) => inputValue === option.address);
								if (inputValue !== '' && !isExisting) {
									filtered.push({
										address: inputValue,
									});
								}
							}

							return filtered;
						}}
					/>
					<Button onClick={handleFormSubmit} disabled={amountError.valid >= 2 || tokenAmount === 0 || targetAddress === null || targetAddress === "" || submitting} variant="contained" fullWidth>
						Send
					</Button>
				</Stack>
			</Stack>
		
	);
};
