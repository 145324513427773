import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { ShortDecimal } from './FormattingComponents';
import { usePrice } from "../PriceContext"

export const EthAmount = ({ value, title, fontSize = "12px", compact = false, short = false, hideCurrency, alignItems = "flex-end", forceEth = false }) => {
	const { ethPrice, nativePrice, native, ethToUsd } = usePrice();

	const usdPrice = forceEth ? ethPrice * parseFloat(value) : nativePrice * parseFloat(value);

	const formatNaN = (i) => {
		return isNaN(i) ? 0 : i;
	}

	const formatShort = (i) => {
		return short ?
			Intl.NumberFormat('en-US', {
				notation: "compact",
				maximumFractionDigits: 1
			}).format(i) : i.toFixed(3);
	}

	return (
		compact ? (
			<Stack direction="row" justifyContent={alignItems}>
				{title ? <Box fontSize={fontSize}>{title}</Box> : <></>}
				<Tooltip size="small" title={`$${formatNaN(usdPrice)}\u00A0USD ${formatNaN(value)}\u00A0${forceEth ? "ETH" : native.symbol}`}>
					<Box fontSize={fontSize}>{`$${formatShort(formatNaN(usdPrice))}${short || hideCurrency ? "" : " USD"}`}</Box>
				</Tooltip>
			</Stack>
		) : (
			<Stack alignItems={alignItems}>
				<ShortDecimal fontSize={fontSize} value={value} unit={forceEth ? "ETH" : native.symbol} />
				<ShortDecimal fontSize={fontSize} value={usdPrice} currency="$" />
			</Stack>
		)
	);
}