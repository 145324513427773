import React, { createContext, useContext } from 'react';
import * as signalR from "@microsoft/signalr";
import { toast } from "react-toastify";
import update from 'immutability-helper';
const FetchDataContext = createContext();

export const FetchDataProvider = ({ fetchData, chain, connection, children, setNotifications, setWalletsToRefresh, setIsAdmin, setIsTester }) => {
	const [adminEvents, setAdminEvents] = React.useState([]);
	const [poolEvents, setPoolEvents] = React.useState([]);
	const [hubConnected, setHubConnected] = React.useState(false);

	const disconnectionTime = React.useRef();
	const hub = React.useRef();
	
	const [latestEvent, setLatestEvent] = React.useState(null);
	const [latestPool, setLatestPool] = React.useState(null);
	const [latestOrder, setLatestOrder] = React.useState(null);

	const [referralData, setReferralData] = React.useState({ loading: true, error: null, data: null });
	const [rewardsData, setRewardsData] = React.useState({ loading: true, error: null, data: null });
	const [orderData, setOrderData] = React.useState({ loading: true, error: null, data: [] });

	const [topTradedTokenData, setTopTradedTokenData] = React.useState({ loading: true, error: null, data: [] });
	const [topTradersData, setTopTradersData] = React.useState({ loading: true, error: null, data: null });


	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	const loadStaleData = () => {
		setOrderData({ loading: true, error: null, data: [] });
		setReferralData({ loading: true, error: null, data: null });
		setRewardsData({ loading: true, error: null, data: null });
		setTopTradedTokenData({ loading: true, error: null, data: [] });
		setTopTradersData({ loading: true, error: null, data: null });

		loadReferralData();
		loadRewardsData();
		loadOrderData();
		loadTopTradedTokenData();
		loadTopTradersData();
		updateFeedData();
		setWalletsToRefresh([]);
	}

	React.useEffect(() => {
		if (latestPool != null && latestPool.chain === chain && latestPool.address) {
			const index = poolEvents.findIndex((pool) => latestPool.address?.toLowerCase() === pool.address?.toLowerCase());
			if (index < 0) {
				setPoolEvents([latestPool, ...poolEvents].sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt); }).slice(0, 20));
			} else {
				const updatedPools = update(poolEvents, { $splice: [[index, 1]] });
				setPoolEvents([latestPool, ...updatedPools].sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt); }));
			}
		}
	}, [latestPool]);

	React.useEffect(() => {
		if (latestEvent != null) {
			setAdminEvents([latestEvent, ...adminEvents].slice(0, 100));
		}
	}, [latestEvent]);

	React.useEffect(() => {
		if (latestOrder != null && latestOrder.chain === chain) {
			const index = orderData.data.findIndex((order) => latestOrder.id === order.id);
			if (index < 0) {
				setOrderData({ loading: false, error: null, data: [latestOrder, ...orderData.data].sort((a, b) => { return new Date(b.dateModified) - new Date(a.dateModified); })});
			} else {
				const updatedOrders = update(orderData.data, { $splice: [[index, 1]] });
				setOrderData({ loading: false, error: null, data: [latestOrder, ...updatedOrders].sort((a, b) => { return new Date(b.dateModified) - new Date(a.dateModified); })});
			}
		}
	}, [latestOrder]);

	const loadReferralData = async () => {
		try {
			const response = await fetchData("/api/user/referrals");
			setReferralData({ loading: false, error: null, data: response });
		} catch (err) {
			setReferralData({ loading: false, error: err, data: null });
		}
	};

	const loadRewardsData = async () => {
		try {
			const response = await fetchData("/api/user/rewards");
			setRewardsData({ loading: false, error: null, data: response });
		} catch (err) {
			setRewardsData({ loading: false, error: err, data: null });
		}
	};

	const loadPermissions = async () => {
		try {
			const response = await fetchData("/api/user/permissions");
			setIsAdmin(response.isAdmin);
			setIsTester(response.isTester)
		} catch (err) {
			setIsAdmin(false);
			setIsTester(false)
		}
	};

	React.useEffect(() => {

		const connectSignalR = async () => {
			if (hub.current) {
				setHubConnected(false);
				await hub.current.stop();
			}
			if (connection.account && connection.signature) {
				hub.current = new signalR.HubConnectionBuilder()
					.withUrl(
						`/eventHub?address=${connection.account}&signature=${connection.signature}`
					)
					.withAutomaticReconnect()
					.build();

				hub.current.on("Event", (newNotification) => {
					if (newNotification.message) {
						setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
						showToast(newNotification.message.substring(0, 250), newNotification.icon ?? "success");
					}
					if (newNotification.refresh) {
						switch (newNotification.refresh) {
							case "rewards":
								setRewardsData({ loading: true, error: null, data: null });
								loadRewardsData();
								break;
							case "referral":
								setReferralData({ loading: true, error: null, data: null });
								loadReferralData();
								break;
							case "function":
								if (newNotification.data) {
									setLatestOrder(newNotification.data)
								}
								break;
						}
					}
				});

				hub.current.on("WalletChange", (indexes) => {
					setWalletsToRefresh(indexes ?? []);
				});

				hub.current.on("Admin", (adminEvent) => {
					//console.log("New admin event", adminEvent)
					setLatestEvent(adminEvent);
				});

				hub.current.on("Pool", (poolEvent) => {
					setLatestPool(poolEvent);
				});

				hub.current.onreconnecting(function () {
					disconnectionTime.current = new Date().getTime() / 1000;
				});

				hub.current.onreconnected(function () {
					const seconds = (new Date().getTime() / 1000) - disconnectionTime.current;
					disconnectionTime.current = null;

					if (seconds > 30) {
						console.log(`Connection reconnected after ${seconds} seconds`)
						loadStaleData();
					}
				});

				hub.current.onclose(async() => {
					await hub.current.start();
					loadStaleData();
				});

				await hub.current.start();
				setHubConnected(true);
			} else {
				setHubConnected(false);
			}
		};

		setOrderData({ loading: true, error: null, data: [] });
		setReferralData({ loading: true, error: null, data: null });
		setRewardsData({ loading: true, error: null, data: null });
		setIsAdmin(false);
		setIsTester(false);

		if (connection.connected) {
			connectSignalR();
			loadPermissions();
			loadReferralData();
			loadRewardsData();
		}

		return async () => {
			if (hub.current) {
				hub.current.off();
				setHubConnected(false);
				await hub.current.stop();
			}
		}
	}, [connection]);

	const loadOrderData = async () => {
		try {
			const data = await fetchData("/api/function");
			setOrderData({ loading: false, error: null, data });
		} catch (err) {
			setOrderData({ loading: false, error: err, data: [] });
		}
	};

	const loadTopTradedTokenData = async () => {
		try {
			const data = await fetchData("/api/leaderboard/toptradedtokens", "GET", null);
			setTopTradedTokenData({ loading: false, error: null, data: data.topTokens });
		} catch (err) {
			setTopTradedTokenData({ loading: false, error: err, data: [] });
		}
	}

	const loadTopTradersData = async () => {
		try {
			const data = await fetchData("/api/leaderboard/toptraders", "GET");
			setTopTradersData({ loading: false, error: null, data: data });
		} catch (err) {
			setTopTradersData({ loading: false, error: err, data: null });
		}
	}

	React.useEffect(() => {
		setOrderData({ loading: true, error: null, data: [] });
		setTopTradedTokenData({ loading: true, error: null, data: [] });
		setTopTradersData({ loading: true, error: null, data: null });

		if (connection.connected) {
			loadOrderData();
			loadTopTradedTokenData();
			loadTopTradersData();
		}
	}, [chain, connection]);

	const updateFeedData = async () => {
		if (hubConnected) {
			const events = await hub.current.invoke("GetLatestEvents");
			setNotifications(events);
			const pools = await hub.current.invoke("GetLatestPools", chain);
			setPoolEvents(pools);
		} else {
			setNotifications([]);
			setPoolEvents([]);
		}
	}

	React.useEffect(() => {
		updateFeedData();
	}, [chain, connection, hubConnected]);



	return (
		<FetchDataContext.Provider value={{ fetchData, chain, connection, adminEvents, poolEvents, referralData, rewardsData, orderData, setLatestOrder, topTradedTokenData, topTradersData }}>
			{children}
		</FetchDataContext.Provider>
	);
};

export const useFetchData = () => {
	return useContext(FetchDataContext);
};
