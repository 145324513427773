import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { ShortDecimal, Token, ShortPercent, TokenLogo, Loading } from '../shared/FormattingComponents'
import { EthAmount } from '../shared/EthAmount'
import Stack from '@mui/material/Stack';
import { useWallets, Wallet } from "../WalletContext";
import { parseEther, parseUnits, formatUnits } from 'viem'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useFetchData } from '../FetchDataContext';
import { usePrice } from "../PriceContext"
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export const WalletBalance = ({ wallet, setSelectedToken, showFinancial = true }) => {
	const { native, usdToNative } = usePrice();
	const navigate = useNavigate();
	const { chain } = useFetchData();
	const { loading, error, loadWallet, walletCount, linkedWallet, wallet1, wallet2, wallet3, wallet4, wallet5 } = useWallets();
	const [isOpen, setIsOpen] = React.useState(false);
	const [showInactive, setShowInactive] = React.useState(false);

	const handleClose = () => {
		setIsOpen(false);
	}

	const handleRowClick = (row) => {
		if (setSelectedToken) {
			setSelectedToken(row);
			navigate("/trade");
		}
	}

	const activeWallet = wallet ?? new Wallet();
	if (wallet == null) {
		const wallets = [wallet1, wallet2, wallet3, wallet4, wallet5]
		activeWallet.loading = loading;
		activeWallet.error = error;
		activeWallet.index = -1;
		activeWallet.nativeBalance = wallets.reduce((x, v) => {
			return formatUnits(parseUnits(x.toString() ?? "0", 18) + parseUnits(v.nativeBalance.toString() ?? "0", 18), 18)
		}, 0);
		const tokenBalance = [];
		const allTokens = [...wallet1.tokenBalance, ...wallet2.tokenBalance, ...wallet3.tokenBalance, ...wallet4.tokenBalance, ...wallet5.tokenBalance];
		for (let i = 0; i < allTokens.length; i++) {
			const existing = tokenBalance.find(x => x.address == allTokens[i].address);
			if (!existing) {
				tokenBalance.push({ ...allTokens[i] });
			} else {
				existing.balance = formatUnits(parseUnits(existing.balance, allTokens[i].decimals) + parseUnits(allTokens[i].balance, allTokens[i].decimals));
			}
		}
		activeWallet.tokenBalance = tokenBalance;
	}

	let inactiveValueEth = usdToNative(0.1);
	if (inactiveValueEth == null || isNaN(inactiveValueEth) || isFinite(inactiveValueEth) == false) {
		inactiveValueEth = 0;
	}

	const activeTokens = activeWallet.tokenBalance.filter(row => formatUnits(row.balance, row.decimals) * (row.price?.nativePrice ?? 0) >= inactiveValueEth);
	const inactiveTokens = activeWallet.tokenBalance.filter(row => formatUnits(row.balance, row.decimals) * (row.price?.nativePrice ?? 0) < inactiveValueEth);

	return (
		<>
			<TableContainer sx={{ maxHeight: "350px", overflow: "auto" }}>
				<Table size="small">
					<TableBody>
						<TableRow
							key={0}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							{(activeWallet.loading) ? (
								<Loading />
							) : (
								<>
									<TableCell align="left">
										<Stack direction="row" alignItems="center" spacing={2}>
											<TokenLogo token={native} size={30} />
											<Typography variant="subtitle2">{native.name}</Typography>
										</Stack>
									</TableCell>
									<TableCell align="right">
										<Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
											<Token value={native} />
											<EthAmount value={formatUnits(activeWallet.nativeBalance, native.decimals)} />
										</Stack>
									</TableCell>
								</>
							)}
						</TableRow>
						{activeTokens.map((row) => (
							<TableRow
								key={row.address}
								sx={{ cursor: "pointer",'&:last-child td, &:last-child th': { border: 0 } }}
								hover
								onClick={() => 	handleRowClick(row)}>
								<TableCell align="left">
									<Stack direction="row" alignItems="center" spacing={2}>
										<TokenLogo token={row} size={30} />
										<Typography variant="subtitle2" sx={{ width: "150px", overflow: "hidden" }}>{row.name}</Typography>
									</Stack>
								</TableCell>
								<TableCell align="right">
									<Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
										{showFinancial ? <>
											<EthAmount compact hideCurrency value={row.price?.nativePrice} currency="$" />
											{row.price?.percentChange24h ?? 0 != 0 ? <ShortPercent value={row.price?.percentChange24h} decimals={2} symbol="24h" /> : <></>}
										</> : <></>}
										<Token value={row} />
										<Stack>
											<ShortDecimal fontSize="12px" value={formatUnits(row.balance, row.decimals)} />
											<EthAmount compact hideCurrency fontSize="12px" value={formatUnits(row.balance, row.decimals) * row.price?.nativePrice} currency="$" />
										</Stack >
									</Stack>
								</TableCell>
							</TableRow>
						))}
						{inactiveTokens.length > 0 ?
							<>
								<TableRow>
									<TableCell align="center" sx={{ cursor: "pointer" }} colSpan={2} onClick={() => { setShowInactive(!showInactive) }}>
										<Stack direction="row" justifyContent="space-between">
											<Typography>{`${inactiveTokens.length} inactive token${inactiveTokens.length === 1 ? "" : "s"}`}</Typography>
											{showInactive ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}
										</Stack>
									</TableCell>
								</TableRow>
								{showInactive ?
										inactiveTokens.map((row) => (
											<TableRow
												hover
												key={row.address}
												sx={{ cursor: "pointer", '&:last-child td, &:last-child th': { border: 0 } }}
												onClick={() => handleRowClick(row)}>
												<TableCell align="left">
													<Stack direction="row" alignItems="center" spacing={2}>
														<TokenLogo token={row} size={30} />
														<Typography variant="subtitle2" sx={{ width: "150px", overflow: "hidden" }}>{row.name}</Typography>
													</Stack>
												</TableCell>
												<TableCell align="right">
													<Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
														{showFinancial ? <>
															<EthAmount compact hideCurrency value={row.price?.nativePrice} currency="$" />
															{ row.price?.percentChange24h ?? 0 != 0 ? <ShortPercent value={row.price?.percentChange24h} decimals={2} symbol="24h" /> : <></>}
														</> : <></>}
														<Token value={row} />
														<Stack>
															<ShortDecimal fontSize="12px" value={formatUnits(row.balance, row.decimals)} />
															<EthAmount compact hideCurrency fontSize="12px" value={formatUnits(row.balance, row.decimals) * row.price?.nativePrice} />
														</Stack >
													</Stack>
												</TableCell>
											</TableRow>
										))
									:
									<></>
								}
							</> :
							<></>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
