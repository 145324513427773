import React, { createContext, useContext } from 'react';
import { useFetchData } from "./FetchDataContext"
const WalletContext = createContext();

export class Wallet {
	constructor(index) {
		this.loading = true;
		this.error = null;
		this.index = index;
		this.address = "";
		this.nativeBalance = 0;
		this.tokenBalance = [];
	}
}

export const WalletProvider = ({ walletsToRefresh, children }) => {
	const { fetchData, connection, chain } = useFetchData();
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(null);

	const [walletCount, setWalletCount] = React.useState(0);
	const [linkedWallet, setLinkedWallet] = React.useState(new Wallet(-1));
	const [wallet1, setWallet1] = React.useState(new Wallet(0));
	const [wallet2, setWallet2] = React.useState(new Wallet(1));
	const [wallet3, setWallet3] = React.useState(new Wallet(2));
	const [wallet4, setWallet4] = React.useState(new Wallet(3));
	const [wallet5, setWallet5] = React.useState(new Wallet(4));

	const wallets = [wallet1, wallet2, wallet3, wallet4, wallet5];
	const walletSetters = [setWallet1, setWallet2, setWallet3, setWallet4, setWallet5];

	const loadWallets = async () => {
		try {
			setLoading(true);
			const data = await fetchData("/api/wallet/");

			setLinkedWallet({
				...linkedWallet,
				...data.linkedWallet,
				loading: false,
				error: null,
			});

			for (let i = 0; i < data.wallets?.length; i++) {
				walletSetters[i]({
					...wallets[i],
					...data.wallets[i],
					loading: false,
					error: null,
				});
			}

			setWalletCount(data.wallets?.length);
		} catch (err) {
			setError(err);
		} finally {
			setLoading(false);
		}
	}

	const loadWallet = async (index) => {
		try {
			switch (index) {
				case -1:
					setLinkedWallet({ ...linkedWallet, loading: true, error: null });
					break;
				case 0:
					setWallet1({ ...wallet1, loading: true, error: null });
					break;
				case 1:
					setWallet2({ ...wallet2, loading: true, error: null });
					break;
				case 2:
					setWallet3({ ...wallet3, loading: true, error: null });
					break;
				case 3:
					setWallet4({ ...wallet4, loading: true, error: null });
					break;
				case 4:
					setWallet5({ ...wallet5, loading: true, error: null });
					break;

				default:
					setWallet1({ ...wallet1, loading: true, error: null });
					setWallet2({ ...wallet2, loading: true, error: null });
					setWallet3({ ...wallet3, loading: true, error: null });
					setWallet4({ ...wallet4, loading: true, error: null });
					setWallet5({ ...wallet5, loading: true, error: null });
					break;
			}

			const data = await fetchData(`/api/wallet/${index}`);

			switch (index) {
				case -1:
					setLinkedWallet({
						...linkedWallet,
						...data,
						loading: false,
						error: null,
					});
					break;
				case 0:
					setWallet1({
						...wallet1,
						...data,
						loading: false,
						error: null,
					});
					break;
				case 1:
					setWallet2({
						...wallet2,
						...data,
						loading: false,
						error: null,
					});
					break;
				case 2:
					setWallet3({
						...wallet3,
						...data,
						loading: false,
						error: null,
					});
					break;
				case 3:
					setWallet4({
						...wallet4,
						...data,
						loading: false,
						error: null,
					});
					break;
				case 4:
					setWallet5({
						...wallet5,
						...data,
						loading: false,
						error: null,
					});
					break;
			}

		} catch (err) {
			switch (index) {
				case -1:
					setLinkedWallet({ ...linkedWallet, loading: false, error: err });
					break;
				case 0:
					setWallet1({ ...wallet1, loading: false, error: err });
					break;
				case 1:
					setWallet2({ ...wallet2, loading: false, error: err });
					break;
				case 2:
					setWallet3({ ...wallet3, loading: false, error: err });
					break;
				case 3:
					setWallet4({ ...wallet4, loading: false, error: err });
					break;
				case 4:
					setWallet5({ ...wallet5, loading: false, error: err });
					break;
			}
		}
	}

	React.useEffect(() => {
		loadWallets();
	}, [connection, fetchData, chain]);

	React.useEffect(() => {
		if (walletsToRefresh.length === 0) {
			setTimeout(() => { loadWallets(); }, 3000);
		} else {
			for (let i = 0; i < walletsToRefresh.length; i++) {
				setTimeout(() => { loadWallet(walletsToRefresh[i]); }, 3000);
			}
		}
	}, [walletsToRefresh]);

	return (
		<WalletContext.Provider value={{ loading, error, loadWallet, walletCount, linkedWallet, wallet1, wallet2, wallet3, wallet4, wallet5  }}>
			{children}
		</WalletContext.Provider>
	);
};

export const useWallets = () => {
	return useContext(WalletContext);
};
