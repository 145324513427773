import React, { useState } from "react";

import { WalletToggle } from "../shared/WalletToggle";
import { WalletBalance } from "../shared/WalletBalance";
import { ShortAddress, Loading } from "../shared/FormattingComponents";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import Stack from '@mui/material/Stack';

import { useWallets } from "../WalletContext";
import { EthAmount } from "../shared/EthAmount";

export const Overview = ({ dashboardData, setSelectedToken }) => {
	const { loading, error } = useWallets();

	const [wallet, setWallet] = useState({ id: 0, wallet: null });

	const handleSelectWallet = (id, wallet) => {
		setWallet({ id: id, wallet: wallet });
	};

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <div>Error fetching wallets: {error.message}</div>;
	}

	return (
		<Stack spacing={2}>
			<WalletToggle value={wallet.id} onChange={handleSelectWallet} />
			{wallet.wallet ? (
					<ShortAddress value={wallet.wallet.address} />
			) : (<></>)}
			<WalletBalance wallet={wallet.wallet} setSelectedToken={setSelectedToken} />

			<Typography variant="h3">1 Month Trade & Snipe Volume</Typography>
			<Stack direction="row" justifyContent="space-evenly">
				<Card elevation={2} sx={{ width: "130px", padding: "10px" }}>
					<Stack direction="column" alignItems="center">
						<AutoGraphIcon fontSize="large" color="primary" />
						<Typography variant="h3">Traded</Typography>
						<EthAmount value={dashboardData.data.tradeValue}/>
					</Stack>
				</Card>
				<Card elevation={2} sx={{ width: "130px", padding: "10px" }}>
					<Stack direction="column" alignItems="center">
						<TrackChangesIcon fontSize="large" color="primary" />
						<Typography variant="h3">Sniped</Typography>
						<EthAmount value={dashboardData.data.snipeValue}/>
					</Stack>
				</Card>
			</Stack>
		</Stack>
	);
};
