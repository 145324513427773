import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import SellIcon from '@mui/icons-material/Sell';
import { StyledTableCell } from '../shared/StyledTable'
import { FunctionIcon, ShortDecimal, DateTime, Token, Pnl, Status } from '../shared/FormattingComponents'
import { EthAmount } from '../shared/EthAmount'
import { TabPanel } from '../shared/TabPanel'
import { DeleteFunction } from '../shared/DeleteFunction'
import { FunctionLogs } from '../shared/FunctionLogs'
import CircularProgress from '@mui/material/CircularProgress';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { useFetchData } from "../FetchDataContext";

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const OrdersHistoryPositions = ({ onDeleted, setSelectedToken, setFastSell }) => {
	const { fetchData, connection, chain, orderData } = useFetchData();

	const [value, setValue] = React.useState(0);
	const [openOrders, setOpenOrders] = React.useState([]);
	const [orderHistory, setOrderHistory] = React.useState([]);
	const [positions, setPositions] = React.useState({ loading: true, error: null, data: []});
	const [idToDelete, setIdToDelete] = React.useState(false);
	const [idToShow, setIdToShow] = React.useState(false);

	const rowsPerPage = 5;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	React.useEffect(() => {
		setOrderHistory(orderData != null && orderData.data.length > 0 ? orderData.data.filter((x) => { return x.dateFulfilled != null && !x.deleted }) : []);
		setOpenOrders(orderData != null && orderData.data.length > 0 ? orderData.data.filter((x) => { return x.dateFulfilled == null && !x.deleted }) : []);
	}, [orderData]);

	React.useEffect(() => {
		const getPositions = async () => {
			try {
				setPositions({ loading: true, error: null, data: [] });
				const positionData = await fetchData("/api/wallet/positions", "GET");
				setPositions({ loading: false, error: null, data: positionData });
			} catch (err) {
				setPositions({ loading: false, error: err, data: [] });
			}
		}

		getPositions();
	}, [connection, orderData, fetchData, chain]);


	const [openOrdersPage, setOpenOrdersPage] = React.useState(0);
	const handleOpenOrdersChangePage = (event, newPage) => {
		setOpenOrdersPage(newPage);
	};

	const [orderHistoryPage, setOrderHistoryPage] = React.useState(0);
	const handleOrderHistoryChangePage = (event, newPage) => {
		setOrderHistoryPage(newPage);
	};

	const [positionsPage, setPositionsPage] = React.useState(0);
	const handlePositionsChangePage = (event, newPage) => {
		setPositionsPage(newPage);
	};


	return  (
		<>
			<DeleteFunction idToDelete={idToDelete} setIdToDelete={setIdToDelete} onDeleted={onDeleted} />
			<FunctionLogs idToShow={idToShow} setIdToShow={setIdToShow} />
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
						<Tab label="Open Orders" {...a11yProps(0)} />
						<Tab label="Order History" {...a11yProps(1)} />
						<Tab label="Positions" {...a11yProps(2)} />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell>Time</StyledTableCell>
									<StyledTableCell align="center">Token</StyledTableCell>
									<StyledTableCell align="center">Type</StyledTableCell>
									<StyledTableCell align="right">Current</StyledTableCell>
									<StyledTableCell align="right">Target</StyledTableCell>
									<StyledTableCell align="right">Amount</StyledTableCell>
									<StyledTableCell align="center">Actions</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{orderData.loading ?
								<TableRow>
										<TableCell colSpan={7} align="center"><CircularProgress size={20} /></TableCell>
								</TableRow> :
								openOrders.length === 0 ?
								<TableRow>
											<TableCell colSpan={7} align="center">No Data</TableCell>
								</TableRow> : (
									openOrders.slice(openOrdersPage * rowsPerPage, openOrdersPage * rowsPerPage + rowsPerPage).map((row) => (
										<TableRow
											key={row.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row"><DateTime value={row.dateModified} /></TableCell>
											<TableCell align="center"><Token onClick={() => { setSelectedToken(row.token); }} value={row.token} /></TableCell>
											<TableCell align="center"><FunctionIcon value={row} /></TableCell>
											<TableCell align="right"><EthAmount value={row.token?.price?.nativePrice} /></TableCell>
											<TableCell align="right"><EthAmount value={row.ethAmount / row.tokenAmount} /></TableCell>
											<TableCell align="right"><ShortDecimal value={row.tokenAmount} /></TableCell>
											<TableCell align="center">
												<Tooltip title="Delete">
													<IconButton size="small" onClick={() => setIdToDelete(row.id)}>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
												<Tooltip title="Logs">
													<IconButton size="small" onClick={() => setIdToShow(row.id)}>
														<ManageSearchIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									)))}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										colSpan={7}
										count={openOrders.length}
										rowsPerPage={rowsPerPage}
										rowsPerPageOptions={[]}
										page={openOrdersPage}
										onPageChange={handleOpenOrdersChangePage}
										showFirstButton
										showLastButton
										sx={{
											'& .MuiToolbar-root': { justifyContent: "space-between" },
											'& .MuiTablePagination-displayedRows': { margin: "auto 0" },
											'& .MuiTablePagination-spacer': { display: "none" }
										}}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell>Time</StyledTableCell>
									<StyledTableCell align="center">Token</StyledTableCell>
									<StyledTableCell align="center">Type</StyledTableCell>
									<StyledTableCell align="right">Price</StyledTableCell>
									<StyledTableCell align="right">Amount</StyledTableCell>
									<StyledTableCell align="center">Status</StyledTableCell>
									<StyledTableCell align="center">Actions</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ orderData.loading ?
									<TableRow>
										<TableCell colSpan={7} align="center"><CircularProgress size={20} /></TableCell>
									</TableRow> :
								orderHistory.length === 0 ?
									<TableRow>
											<TableCell colSpan={7} align="center">No Data</TableCell>
									</TableRow> : (
											orderHistory.slice(orderHistoryPage * rowsPerPage, orderHistoryPage * rowsPerPage + rowsPerPage).map((row) => (
									<TableRow
										key={row.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row"><DateTime value={row.dateModified} /></TableCell>
										<TableCell align="center"><Token onClick={() => { setSelectedToken(row.token); }} value={row.token} /></TableCell>
										<TableCell align="center"><FunctionIcon value={row} /></TableCell>
										<TableCell align="right"><EthAmount value={row.ethAmount} /></TableCell>
										<TableCell align="right"><ShortDecimal value={row.tokenAmount} /></TableCell>
										<TableCell align="center"><Status value={row.status} /></TableCell>
										<TableCell align="center">
											<Tooltip title="Logs">
												<IconButton size="small" onClick={() => setIdToShow(row.id) }>
													<ManageSearchIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								)))}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										colSpan={7}
										count={orderHistory.length}
										rowsPerPage={rowsPerPage}
										rowsPerPageOptions={[]}
										page={orderHistoryPage}
										onPageChange={handleOrderHistoryChangePage}
										showFirstButton
										showLastButton
										sx={{
											'& .MuiToolbar-root': { justifyContent: "space-between" },
											'& .MuiTablePagination-displayedRows': { margin: "auto 0" },
											'& .MuiTablePagination-spacer': { display: "none" }
										}}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</TabPanel>
				<TabPanel value={value} index={2} sx={{ width: '100%', overflow: 'hidden' }}>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<StyledTableCell>Time</StyledTableCell>
									<StyledTableCell align="center">Token</StyledTableCell>
									<StyledTableCell align="right">Bought</StyledTableCell>
									<StyledTableCell align="right">Paid</StyledTableCell>
									<StyledTableCell align="right">Value</StyledTableCell>
									<StyledTableCell align="right">PnL</StyledTableCell>
									<StyledTableCell align="center">Actions</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{positions.loading ?
									<TableRow>
										<TableCell colSpan={7} align="center"><CircularProgress size={20} /></TableCell>
									</TableRow>:
									positions.data.length === 0 ?
										<TableRow>
											<TableCell colSpan={7} align="center">{positions.error ? "Error retrieving positions" : "No Data"}</TableCell>
										</TableRow> : (

										positions.data.slice(positionsPage * rowsPerPage, positionsPage * rowsPerPage + rowsPerPage).map((row) => (
										<TableRow
											key={row.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row"><DateTime value={row.timeStamp} /></TableCell>
											<TableCell align="center"><Token onClick={() => { setSelectedToken(row.token); }} value={row.token} /></TableCell>
											<TableCell align="right"><ShortDecimal value={row.toAmount} /></TableCell>
											<TableCell align="right"><EthAmount value={row.fromAmount} /></TableCell>
											<TableCell align="right"><EthAmount value={row.currentValue} /></TableCell>
											<TableCell align="right"><Pnl from={row.fromAmount} current={row.currentValue} /></TableCell>
												<TableCell align="center">
													<Tooltip title="Sell">
														<IconButton size="small" onClick={() => { setFastSell(row); } }>
															<SellIcon />
														</IconButton>
													</Tooltip>
											</TableCell>
										</TableRow>
									))
								 )}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										colSpan={7}
										count={positions.data.length}
										rowsPerPage={rowsPerPage}
										rowsPerPageOptions={[]}
										page={positionsPage}
										onPageChange={handlePositionsChangePage}
										showFirstButton
										showLastButton
										sx={{
											'& .MuiToolbar-root': { justifyContent: "space-between" },
											'& .MuiTablePagination-displayedRows': { margin: "auto 0" },
											'& .MuiTablePagination-spacer': { display: "none" }
										}}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</TabPanel>
			</Box>
		</>
	);
}
