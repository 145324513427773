import React from "react";
import Stack from '@mui/material/Stack';
import CardActionArea from '@mui/material/CardActionArea';
import TableContainer from '@mui/material/TableContainer';
import Card from '@mui/material/Card';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PendingIcon from '@mui/icons-material/Pending';
import PaidIcon from '@mui/icons-material/Paid';
import { Loading } from "../shared/FormattingComponents";
import Typography from '@mui/material/Typography';
import { useFetchData } from "../FetchDataContext";
import { useNavigate } from "react-router-dom";
import { EthAmount } from "../shared/EthAmount";

export const TotalReferralsDash = () => {
	const { referralData } = useFetchData();
	const navigate = useNavigate();

	return (
		<CardActionArea sx={{ height: "100%", display: "flex", alignItems: "flex-start" }} onClick={() => { navigate("/referrals"); }}>
			<Stack justifyContent="space-evenly" alignItems="center" sx={{ width: "100%"}} spacing={2}>
				<Typography variant="h3">Referrals</Typography>
				{referralData.loading ?
					<Loading /> :
					<Stack direction="row" spacing={1}>
						<Card variant="outlined" sx={{ width: "100px", padding: "5px 0" }}>
							<Stack direction="column" alignItems="center">
								<Tooltip title="Total Referrals"><GroupsIcon fontSize="large" color="primary" /></Tooltip>
								<Typography variant="h3">{referralData.data.totalReferrals}</Typography>
							</Stack>
						</Card>
						<Card variant="outlined" sx={{ width: "100px", padding: "5px 0"}}>
							<Stack direction="column" alignItems="center">
								<Tooltip title="Rewards Owed"><PaidIcon fontSize="large" color="primary" /></Tooltip>
								<EthAmount value={referralData.data.unclaimedRewards} compact hideCurrency forceEth/>
							</Stack>
						</Card>
					</Stack>
				}
			</Stack>
		</CardActionArea>
	);
};
