import React from "react";
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { TokenAmount } from '../shared/TokenAmount'
import { ShortDecimal, WholeNumber } from '../shared/FormattingComponents'
import { usePrice } from "../PriceContext"
import { useFetchData } from "../FetchDataContext";

export const Flashbots = ({ useFlashbots, setUseFlashbots, bribe, setBribe }) => {
	const { latestPriority, gasPrice } = usePrice();
	const { chain } = useFetchData();

	const sanitizeInput = (input) => {
		return input.trim().replace(/[^0-9]/g, "");
	};

	const handleChange = (event) => {
		setUseFlashbots(event.target.checked);
	};

	const handleBribeChange = (e) => {
		const sanitizedValue = sanitizeInput(e.target.value);
		setBribe(sanitizedValue);
	};

	const recommendedBasePriorityFee = (parseFloat(gasPrice?.fastGasPrice ?? 0) - parseFloat(gasPrice?.suggestBaseFee ?? 0)) + 3;

	return (
		chain == "eth" ?
		<>
			<Box sx={{ padding: "0 20px" }}>
				<Stack direction="row" justifyContent="space-between">
					<FormControlLabel size="small" control={<Switch checked={useFlashbots} size="small" onChange={handleChange} />} label="Anti-MEV Protection" />
						<Tooltip title="Enabling Anti-MEV protection will send your transaction directly to bundlers and not be included in the public mempool. Setting a priority fee too low may cause your transaction to be ignored and eventually fail.">
						<InfoIcon fontSize="small" color="warning" />
					</Tooltip>
				</Stack>
			</Box>
			{useFlashbots ?
				<Stack spacing={0}>
					<TokenAmount label="Priority Fee" onChange={handleBribeChange} value={bribe} setValue={setBribe} symbol="Gwei" />
					<ShortDecimal sx={{ width: "100%", textAlign: "right" }} fontSize="12px" value={latestPriority.latest} currency="Latest priority Fee: " unit="Gwei" />
					<ShortDecimal sx={{ width: "100%", textAlign: "right" }} fontSize="12px" value={latestPriority.average} currency="Average priority Fee: " unit="Gwei" />
				</Stack> :
				<Stack spacing={0}>
					<TokenAmount label="Priority Fee" onChange={handleBribeChange} value={bribe} setValue={setBribe} symbol="Gwei" />
					<ShortDecimal sx={{ width: "100%", textAlign: "right" }} fontSize="12px" value={Math.ceil(recommendedBasePriorityFee)} currency="Recommended priority Fee: " unit="Gwei" />
				</Stack>
			}
			</> :
			chain === "bsc" ? <Stack spacing={0}>
				<TokenAmount label="Priority Fee" onChange={handleBribeChange} value={bribe} setValue={setBribe} symbol="Gwei" />
				<ShortDecimal sx={{ width: "100%", textAlign: "right" }} fontSize="12px" value={Math.ceil(recommendedBasePriorityFee)} currency="Recommended priority Fee: " unit="Gwei" />
			</Stack> :
			chain === "sol" ? <>
				<TokenAmount label="Priority Fee" onChange={handleBribeChange} value={bribe} setValue={setBribe} symbol="ML" />
				<ButtonGroup size="small" sx={{ m: "1px !important", justifyContent: "center" }}>
					<Button onClick={() => { setBribe(Math.round(latestPriority.min)) }}>MIN</Button>
					<Button onClick={() => { setBribe(Math.round(latestPriority.low)) }}>LOW</Button>
					<Button onClick={() => { setBribe(Math.round(latestPriority.medium)) }}>MEDIUM</Button>
					<Button onClick={() => { setBribe(Math.round(latestPriority.high)) }}>HIGH</Button>
					<Button onClick={() => { setBribe(Math.round(latestPriority.veryHigh)) }}>VERY HIGH</Button>
				</ButtonGroup>
		</> :
		<></>
	);
}