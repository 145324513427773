import React from "react";
import { useFetchData } from "../FetchDataContext"
import { useDarkMode } from '../DarkModeContext';


export const TradingViewChart = ({ symbol, style }) => {
	const { fetchData } = useFetchData();
	const { darkMode } = useDarkMode();
	const [pair, setPair] = React.useState({ chainId: "", pairAddress: ""});


	React.useEffect(() => {

		const loadPair = async () => {
			const pairs = await fetchData(`/api/token/pairs/${symbol}`);
			if (pairs.length > 0) {
				setPair(pairs[0]);
			} else {
				setPair({ chainId: "", pairAddress: "" });
			}
		}

		loadPair();
	}, [fetchData, darkMode, symbol]);

	return (
		<div style={style}>
			<div id="dexscreener-embed" style={{ position: "relative", width: "100%", height: "100%" }}>
				<iframe id="tv-chart" style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: 0 }} src={`https://dexscreener.com/${pair.chainId}/${pair.pairAddress}?embed=1&theme=${darkMode ? "dark" : "light"}&trades=0&info=0`}></iframe>
			</div>
		</div>
	);
}