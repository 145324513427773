import Chip from '@mui/material/Chip';
import SellIcon from '@mui/icons-material/Sell';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { CopyToClipboard } from './CopyToClipboard'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import PendingIcon from '@mui/icons-material/Pending';
import HighlightIcon from '@mui/icons-material/Highlight';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import HelpIcon from '@mui/icons-material/Help';
import { usePrice } from '../PriceContext';
import { useFetchData } from '../FetchDataContext';

export const numberExponentToLarge = (numIn) => {
	numIn += "";                                            // To cater to numric entries
	var sign = "";                                           // To remember the number sign
	//numIn.charAt(0) == "-" && (numIn = numIn.substring(1), sign = "-"); // remove - sign & remember it
	var str = numIn.split(/[eE]/g);                        // Split numberic string at e or E
	if (str.length < 2) return sign + numIn;                   // Not an Exponent Number? Exit with orginal Num back
	var power = str[1];                                    // Get Exponent (Power) (could be + or -)
	if (power == 0 || power == -0) return sign + str[0];       // If 0 exponents (i.e. 0|-0|+0) then That's any easy one

	var deciSp = 1.1.toLocaleString().substring(1, 2);  // Get Deciaml Separator
	str = str[0].split(deciSp);                        // Split the Base Number into LH and RH at the decimal point
	var baseRH = str[1] || "",                         // RH Base part. Make sure we have a RH fraction else ""
		baseLH = str[0];                               // LH base part.

	if (power > 0) {   // ------- Positive Exponents (Process the RH Base Part)
		if (power > baseRH.length) baseRH += "0".repeat(power - baseRH.length); // Pad with "0" at RH
		baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power);      // Insert decSep at the correct place into RH base
		if (baseRH.charAt(baseRH.length - 1) == deciSp) baseRH = baseRH.slice(0, -1); // If decSep at RH end? => remove it

	} else {         // ------- Negative Exponents (Process the LH Base Part)
		let num = Math.abs(power) - baseLH.length;                               // Delta necessary 0's
		if (num > 0) baseLH = "0".repeat(num) + baseLH;                       // Pad with "0" at LH
		baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power);     // Insert "." at the correct place into LH base
		if (baseLH.charAt(0) == deciSp) baseLH = "0" + baseLH;                // If decSep at LH most? => add "0"
	}
	return sign + baseLH + baseRH;                                          // Return the long number (with sign)
}

export const stringToColor = (string) => {
	let hash = 0;
	let i;
	string = string ?? "1234"

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export const TokenLogo = ({ token, size }) => {
	const { chain } = useFetchData();
	const variant = (token.address ?? "").toLowerCase() === "0x185ece9bc75164f9fc0fbe44738e8dd1863f8464" ? "square" : "circular"
	return <Avatar variant={variant} {...tokenAvatar(token, size)} src={token.logo ?? `/api/token/logo/${chain}/${token.address}`} />
}

export const tokenAvatar = (token, size) => {
	return {
		sx: {
			width: `${size}px`,
			height: `${size}px`
		},
		children: <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: stringToColor(token?.symbol) }}>{token?.symbol?.substring(0, 2)}</div>
	};
}

export const FunctionIcon = ({ value }) => {
	switch (value.functionName.toLowerCase()) {
		case "buy order":
			return <Tooltip title={`${value.settings.orderType} Buy Order`}><ShoppingCartIcon fontSize="small" /></Tooltip>
		case "sell order":
			return <Tooltip title={`${value.settings.orderType} Sell Order`}><SellIcon fontSize="small" /></Tooltip>
		case "shotgun buy":
			return <Tooltip title="Shotgun Buy"><HighlightIcon fontSize="small" /></Tooltip>
		case "launch sniper":
			return <Tooltip title="Launch Sniper"><TrackChangesIcon fontSize="small" /></Tooltip>
		default:
			console.log(value)
			return <>{value.functionName}</>
	}
}

export const ShortDecimal = ({ value, unit = null, currency = null, fontSize, sx }) => {
	try {
		value = value === 0 || isNaN(value) ? 0 : numberExponentToLarge(value);
		return <Tooltip size="small" title={`${currency ?? ""}${value}${unit ? `\u00A0${unit}` : ""}`}><Box sx={sx} fontSize={fontSize}>{currency ? currency : ""}{(Math.floor(parseFloat(value) * 1000) / 1000).toFixed(3)}{unit ? `\u00A0${unit}`:""}</Box></Tooltip>
	} catch {
		return value;
	}
}

export const WholeNumber= ({ value, unit = null, currency = null, fontSize, sx }) => {
	try {
		value = numberExponentToLarge(value);
		return <Tooltip size="small" title={`${currency ?? ""}${value}${unit ? `\u00A0${unit}` : ""}`}><Box sx={sx} fontSize={fontSize}>{currency ? currency : ""}{(Math.floor(parseFloat(value) * 1000) / 1000).toFixed(0)}{unit ? `\u00A0${unit}` : ""}</Box></Tooltip>
	} catch {
		return value;
	}
}


export const Pnl = ({ from, current }) => {
	const profit = current - from;
	const percent = profit / from * 100;
	try {
		return (
			<Tooltip size="small" title={`${profit >= 0 ? "+" : ""}${profit}`}>
				<Box sx={{ color: profit >= 0 ? "#009900" : "#ff0000" }}>{profit > 0 ? "+" :  ""}{parseFloat(percent).toFixed(2)}%</Box>
			</Tooltip>
		)
	} catch {
		return profit;
	}
}

export const ShortPercent= ({ value, decimals = 0, symbol = null }) => {
	try {
		let d = parseFloat(value)
		let short = d.toFixed(decimals); 
		return (
			<Tooltip title={`${d}%`}>
				<Chip
					size="small"
					color={d > 0 ? "success" : d === 0 ? "primary" : "error"}
					label={`${d > 0 ? "+" : ""}${short}%${symbol ? ` ${symbol}`: ""}`}
				/>
			</Tooltip>
		)
	} catch {
		return value;
	}
}


export const ShortAddress = ({ value }) => {
	try {
		return <Tooltip size="small" title={value}><Chip
			icon={<CopyToClipboard text={value} />}
			label={`${value.substring(0, 8)}...${value.substring(36)}`} /></Tooltip>
	} catch {
		return value;
	}
}


export const DateTime = ({ value }) => {
	try {
		return <Tooltip size="small" title={value.toString()}><Box>{new Date(value).toLocaleDateString()}</Box></Tooltip>
	} catch {
		return <>value</>;
	}
}

export const PageHeader = ({ pageName, helpUrl }) => {
	return (
		<Stack direction="row" alignItems="center" sx={{ marginTop: "10px", marginBottom: "20px"}}>
			<Typography variant="h1">{pageName}</Typography>
			{helpUrl ?
				<IconButton href={helpUrl} sx={{ padding: "2px", marginLeft: "10px"}} target="_blank">
					<HelpIcon color="info" />
				</IconButton> : <></>
			}
		</Stack>
	)
}

export const Token = ({ value, onClick }) => {
	if (value?.symbol == null && value?.address == null) return <></>;

	let label = value?.symbol ?? `${value?.address?.substring(0, 8)}...${value?.address?.substring(36)}`;
	if (label.length > 15) label = label.substring(0, 10);
	return value == null ? <></> : (
		<Tooltip size="small" title={
			<Stack direction="row" alignItems="center">
				<>{`${value.name} (${value.symbol}) `}</>
				{value.address ?
					<>
						<>{`${value.address.substring(0, 8)}...${value.address.substring(36)}`}</>
						<CopyToClipboard text={value.address} />
					</>
				: <></> }
			</Stack> 
		}>
			<Chip style={{ fontSize: "0.6rem" }} onClick={onClick} label={label} />
		</Tooltip>
	) 
}

export const Status = ({ value }) => {
	let icon = <></>
	switch (value) {
		case "Deleted":
			icon = <DeleteForeverIcon color="error" />
			break;
		case "Failed":
			icon = <ErrorIcon color="error" />
			break;
		case "Success":
			icon = <CheckCircleIcon color="success" />
			break;
		case "Expired":
			icon = <TimerOffIcon color="error" />
			break;
		case "In Progress":
			icon = <PendingIcon color="warning" />
			break;
	}

	return <Tooltip size="small" title={value}>
		{ icon }
	</Tooltip>
}

export const parseTransactions = (text, token, chain) => {
	return highlightPattern(text, /0x[0-9a-fA-F]{64}|0x[0-9a-fA-F]{40}|[a-zA-Z0-9]{87,88}|[a-zA-Z0-9]{43,44}/gi, token, chain);
}

const highlightPattern = (text, pattern, token, chain) => {
	const splitText = text.split(pattern);

	if (splitText.length <= 1) {
		return text;
	}

	const matches = text.match(pattern);

	return splitText.reduce((arr, element, index) => (matches[index] ? [
		...arr,
		element,
		matches[index].length >= 42 && matches[index].length <= 44 ? <Token key={index} value={token ?? { address: matches[index] }} /> :
		matches[index].length === 87 || matches[index].length === 88 ? <SolTransaction key={index} value={matches[index]} /> :
				chain === "bsc" ? <BscTransaction key={index} value={matches[index]} /> :
					<EthTransaction key={index} value={matches[index]} />
	] : [...arr, element]), []);
};

export const EthTransaction = ({ value }) => {
	return (
		<Tooltip size="small" title={value}>
			<Link href={`https://etherscan.io/tx/${value}`} target="_blank" rel="noreferrer">
				<Chip style={{ fontSize: "0.6rem" }} size="small" label={`TX: ${value.substring(0, 8)}...${value.substring(58)}`} />
			</Link>
		</Tooltip>
	)
}
export const BscTransaction = ({ value }) => {
	return (
		<Tooltip size="small" title={value}>
			<Link href={`https://bscscan.com/tx/${value}`} target="_blank" rel="noreferrer">
				<Chip style={{ fontSize: "0.6rem" }} size="small" label={`TX: ${value.substring(0, 8)}...${value.substring(58)}`} />
			</Link>
		</Tooltip>
	)
}


export const SolTransaction = ({ value }) => {
	return (
		<Tooltip size="small" title={value}>
			<Link href={`https://solscan.io/tx/${value}`} target="_blank" rel="noreferrer">
				<Chip style={{ fontSize: "0.6rem" }} size="small" label={`TX: ${value.substring(0, 8)}...${value.substring(58)}`} />
			</Link>
		</Tooltip>
	)
}

export const ComingSoon = ({text = "Coming Soon"}) => {
	return (
		<div style={{
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: 1000,
			position: "absolute",
			borderRadius: "3px",
			margin: "-1px",
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		}}>
			<Typography variant="h1" style={{
				textAlign: "center",
				color: "#FFF",
				fontSize: "1.2rem"
			}}>{text}</Typography>
		</div>
	)
}

export const Loading = () => {
	return (
		<Box sx={{ position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", top: 0, left: 0, right: 0, bottom: 0}}>
			<CircularProgress />
		</Box>
	);
}

export const PrettyNumber = ({ value }) => {
	return Intl.NumberFormat('en-US', {
		notation: "compact",
		maximumFractionDigits: 1
	}).format(value);
}

export const Dex = ({ value }) => {
	const dex = value.startsWith("uniswap") ? "uniswap" : value.startsWith("pancakeswap") ? "pancakeswap" : value;
	const icon = `/img/${dex}.webp`;
	const version = value.endsWith("v3") ? "V3" : value.endsWith("v2") ? "V2" : null; 

	if (version) {
		return (
			<Badge badgeContent={version}>
				<Avatar src={icon} sx={{ width: 24, height: 24 }}>?</Avatar>
			</Badge>
		);
	}

	return (
		<Avatar src={icon} sx={{ width: 24, height: 24 }}>?</Avatar>	
	);
}

export const ChainIcon = ({ value }) => {
	switch (value) {
		case "eth":
			return <Avatar src="/img/eth.png" sx={{ width: 20, height: 20, padding: "1px" }} />;
		case "bsc":
			return <Avatar src="/img/bsc.svg" sx={{ width: 20, height: 20, padding: "1px" }} />
		case "sol":
			return <Avatar src="/img/sol.png" sx={{ width: 20, height: 20, padding: "1px" }} />
		default:
			return <Avatar variant="square" src="/img/undx.png" sx={{ width: 20, height: 20, padding: "1px" }} />
	}
}