import React, { useEffect, useState } from "react";
import { Overview } from "./dashboard/Overview";
import { TotalReferralsDash } from "./dashboard/TotalReferralsDash";
import { BestTraders } from "./trade/BestTraders";
import { UnclaimedReward } from "./dashboard/Unclaimed";
import { NewPools } from './shared/NewPools';
import { ProfitableTotal } from "./dashboard/ProfitableTotal";
import { HotPairs } from "./dashboard/HotPairs";
import { Chat } from "./dashboard/Chat";
import Box from '@mui/material/Box';
import { PageHeader } from './shared/FormattingComponents';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PageSection, CardSection } from './shared/PageSection'
import { useFetchData } from "./FetchDataContext";


export const Dashboard = ({ setSelectedToken }) => {
	const { fetchData, connection, chain } = useFetchData();

	const [dashboardData, setDashboardData] = useState({ loading: true, error: null, data: {} });

	const loadDashboardData = async () => {
		try {
			setDashboardData({ loading: true, error: null, data: {} });
			const response = await fetchData("/api/user/dashboard", "get");
			setDashboardData({ loading: false, error: null, data: response });
		} catch (err) {
			setDashboardData({ loading: false, error: err, data: {} });
		}
	};

	useEffect(() => {
		loadDashboardData();
	}, [connection, fetchData, chain]);

	if (dashboardData.error) {
		return <div>Error: {dashboardData.error.message}</div>;
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<PageHeader pageName="Dashboard" helpUrl="https://unodex.gitbook.io/docs/unodex-trading-app/introduction" />
			<Grid container spacing="20px">
				<Grid item xs={12} md={12} lg={5}>
					<PageSection sx={{ minHeight: "460px"  }}>
						<Overview dashboardData={dashboardData} setSelectedToken={setSelectedToken} />
					</PageSection>
				</Grid>
				
				<Grid item xs={12} md={12} lg={7}>
					<Grid container spacing="20px">
						<Grid item xs={4} md={4} lg={4}>
							<PageSection xs={{ padding: "20px", textAlign: "center" }}>
								<TotalReferralsDash dashboardData={dashboardData} />
							</PageSection>
						</Grid>
						<Grid item xs={4} md={4} lg={4}>
							<PageSection xs={{ marginTop: "50px", textAlign: "center" }}>
								<ProfitableTotal dashboardData={dashboardData}
								/>
							</PageSection>
						</Grid>
						<Grid item xs={4} md={4} lg={4}>
							<PageSection xs={{ padding: "20px", textAlign: "center" }}>
								<UnclaimedReward dashboardData={dashboardData} />
							</PageSection>
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							<PageSection sx={{ minHeight: "320px"}}>
								<NewPools setSelectedToken={setSelectedToken} />
							</PageSection>
						</Grid>
					</Grid>

	
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<PageSection sx={{ minHeight: "350px" }}>
						<BestTraders />
					</PageSection>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<PageSection sx={{ minHeight: "350px" }}>
						<HotPairs />
					</PageSection>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<PageSection sx={{ minHeight: "350px" }}>
						<Chat  />
					</PageSection>
				</Grid>
			</Grid>
		</Box>
	);
};