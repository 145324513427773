import React from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFetchData } from "../FetchDataContext";

export const PoolSelect = ({ token, pool, setPool }) => {
	const { chain } = useFetchData();
	const [pools, setPools] = React.useState([{ label: "Auto", value: "" }])

	React.useEffect(() => {
		const dex = chain === "bsc" ? "Pancakeswap" : "Uniswap"
		const tokenPools = [{ label: "Auto", value: "" }];
		if (token?.pools) {
			if (token.pools.hasUniswapV2) tokenPools.push({ label: dex + " V2", value: "V2" });
			if (token.pools.hasUniswapV3OnePercent) tokenPools.push({ label: dex + " V3 1%", value: "V3_10000" });
			if (token.pools.hasUniswapV3PointThreePercent) tokenPools.push({ label: dex + " V3 0.3%", value: "V3_3000" });
			if (token.pools.hasUniswapV3PointZeroFivePercent) tokenPools.push({ label: dex + " V3 0.05%", value: "V3_500" });
			if (token.pools.hasUniswapV3PointZeroOnePercent) tokenPools.push({ label: dex + " V3 0.01%", value: "V3_100" });
		}
		setPools(tokenPools);
	}, [token]);

	return (
		<FormControl fullWidth>
			<InputLabel shrink={true}>Pool</InputLabel>
			<Select
				value={pool}
				displayEmpty={true}
				label="Pool"
				size="small"
				onChange={(evt) => { setPool(evt.target.value); }}
			>
				{pools.map((option, index) => {
					return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>;
				})}
			</Select>
		</FormControl>
	);
}