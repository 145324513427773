import React from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { StyledTableCell } from '../shared/StyledTable';
import { Loading } from '../shared/FormattingComponents';
import { useFetchData } from "../FetchDataContext";

export const UsersOverview = () => {
	const { fetchData } = useFetchData();
	const [loading, setLoading] = React.useState(false);
	const [userData, setUserData] = React.useState([]);


	React.useEffect(() => {
		const getRewardData = async () => {
			try {
				const response = await fetchData(`/api/admin/users`, "get");
				setUserData(response);
				setLoading(false);
			} catch (err) {
				setUserData([]);
			}
		}

		if (loading) {
			getRewardData();
		}
	}, [loading]);

	return (
		<>
			<Typography variant="h3">User Data</Typography>
			{loading ?
				<Loading /> :
				userData.length === 0 ?
					<Button onClick={() => { setLoading(true); }}>Load Data</Button> :

						<Box sx={{ width:"100%", height: "300px", overflow: "scroll" }}>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<StyledTableCell>Date</StyledTableCell>
											<StyledTableCell align="right">Accounts Created</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userData?.map((row) => (
											<TableRow
												key={row.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row">{row.key}</TableCell>
												<TableCell align="right">{row.count}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{userData?.length > 0 ? (
								<></>
							) : (
								<p align="center">No user data available</p>
							)}
						</Box>
				}
			
		</>
	);

}