import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField'
import DataObjectIcon from '@mui/icons-material/DataObject';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Switch from '@mui/material/Switch';
import { FunctionLogs } from '../shared/FunctionLogs'
import { CopyToClipboard } from '../shared/CopyToClipboard'
import { StyledTableCell } from '../shared/StyledTable';
import { Loading, Token, Status, FunctionIcon, ChainIcon, DateTime } from '../shared/FormattingComponents';
import { useFetchData } from "../FetchDataContext";

export const UserLookup = () => {
	const { fetchData } = useFetchData();
	const [loading, setLoading] = React.useState(false);
	const [userId, setUserId] = React.useState("");
	const [user, setUser] = React.useState(null);
	const [idToShow, setIdToShow] = React.useState(false);

	const loadUserData = async (uid) => {
		setLoading(true);
		try {
			setUser(await fetchData(`/api/admin/users/${uid}`, "get"));
			setLoading(false);			
		} catch (error) {

		}
	}

	const showToast = (message, type = "success") => {
		toast[type](message, {
			position: "bottom-right",
		});
	};

	const setTester = async (uid, on) => {
		try {
			await fetchData(`/api/admin/users/${uid}/permissions?isTester=${on}`, "post")
		} catch (error) {
			showToast(
				`Error editing user ${uid}.`,
				"error"
			);
		}
	}



	React.useEffect(() => {
		if (userId.length === 42) {
			loadUserData(userId);
		} else {
			setUser(null);
		}
	}, [userId]);

	return (
		<>
			<FunctionLogs idToShow={idToShow} setIdToShow={setIdToShow} />
			<Typography variant="h3">Lookup User</Typography>
			<Stack direction="row">
				<TextField
					onChange={(e) => setUserId(e.target.value)}
					value={userId}
					label="User ID"
					variant="outlined"
				/>
				{user !== null ?
					<FormControlLabel onChange={async (e) => await setTester(user.id, e.target.checked)} control={(<Switch defaultChecked={user.isTester} />)} label="Is Tester" />
					: <></>}
			</Stack>
			{
				loading ? <Loading /> :
				user === null ? <p align="center">No user data available</p> :
					<>
						{user.evmWallets.map((row, index) => { return <Chip key={index + 1} avatar={<Avatar>{index + 1}</Avatar>} label={<Stack direction="row">{`${row.substring(0, 8)}...${row.substring(36)}`}<CopyToClipboard text={row} /></Stack>} /> })}
						{user.solWallets.map((row, index) => { return <Chip key={index + 1} avatar={<Avatar>{index + 1}</Avatar>} label={<Stack direction="row">{`${row.substring(0, 8)}...${row.substring(36)}`}<CopyToClipboard text={row} /></Stack>} /> })}
						<Box sx={{ width:"100%", height: "300px", overflow: "scroll" }}>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<StyledTableCell>Chain</StyledTableCell>
											<StyledTableCell>Function</StyledTableCell>
											<StyledTableCell>Token</StyledTableCell>
											<StyledTableCell>Status</StyledTableCell>
											<StyledTableCell>Last Update</StyledTableCell>
											<StyledTableCell>Actions</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{user.functions.map((row) => (
											<TableRow
												key={row.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell><ChainIcon value={row.chain} /></TableCell>
												<TableCell><FunctionIcon value={row} /></TableCell>
												<TableCell><Token value={{ address: row.targetAddress }} /></TableCell>
												<TableCell><Status value={row.status} /></TableCell>
												<TableCell><DateTime value={row.dateModified} /></TableCell>
												<TableCell>
													<Tooltip title="Logs">
														<IconButton size="small" onClick={() => setIdToShow(row.id)}>
															<ManageSearchIcon />
														</IconButton>
													</Tooltip>
													<Tooltip title={<pre>{JSON.stringify(row, null, 4)}</pre>} >
														<DataObjectIcon />
													</Tooltip>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</>
				}
			
		</>
	);

}