import React from 'react';
import { useDarkMode } from './DarkModeContext';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MdOutlineDarkMode } from "react-icons/md";
import { IoSunnyOutline } from "react-icons/io5";

const DarkModeToggle = () => {
	const { darkMode, toggleDarkMode } = useDarkMode();

	return (
		<IconButton onClick={toggleDarkMode}>
			{darkMode ? <IoSunnyOutline /> : <MdOutlineDarkMode />}
		</IconButton>
	);
};

export default DarkModeToggle;