import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { usePrice } from "../PriceContext"
import { useFetchData } from '../FetchDataContext';


export const GasPrice = () => {
	const { chain } = useFetchData();
	const { gasPrice } = usePrice();

	const proposeGasPrice = parseFloat(gasPrice?.proposeGasPrice ?? 0);

	return chain === "eth" || chain === "bsc"  ? (
		<Chip label={proposeGasPrice == 0 ? <CircularProgress size={10} /> : <>{Math.round(proposeGasPrice)}<Box sx={{ display: {xs: "none", sm: "inline-block"} }}>&nbsp;Gwei</Box></>} icon={<LocalGasStationIcon />}></Chip>
	) : <></>;
}