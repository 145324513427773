import React from "react";
import Box from '@mui/material/Box';

import { useDarkMode } from "./DarkModeContext";

export const Layout =({children}) => {
	const {darkMode} = useDarkMode();

	return (
		<Box xs={{ backgroundColor: "background.main", minHeight: '100vh' }}>{children}</Box>
	);
};
