import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ErrorIcon from '@mui/icons-material/Error';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { ShortDecimal } from './FormattingComponents';
import { usePrice } from "../PriceContext"

export const TokenAmount = ({ symbol, label, value, setValue, onChange, max, error, showPercentageSlider = false, readOnly = false, showTextInput = true, showBalance = true }) => {
	const { nativePrice, native } = usePrice();

	const onSliderChange = (evt, value) => {
		setValue(max / 100 * value)
	}

	const validation = error === null || !error?.valid ? 0 : error.valid;
	const isValid = error === null ? true : validation === 0;
	const errorColor = validation === 2 ? "error" : validation === 1 ? "warning" : "primary";

	return (
		<>
			<FormControl fullWidth sx={{ m: 1 }} variant="outlined" focused={isValid === false}>
				{showTextInput ? 
					<>
						<InputLabel shrink color={errorColor}>
							{ isValid ? (<>
								{label}
							</>) : (<>{label} <Tooltip title={error.message}><ErrorIcon /></Tooltip></>)}
						</InputLabel>
						<OutlinedInput
							readOnly={readOnly}
							label={label}
							color={errorColor}
							endAdornment={<>
								{max ? <InputAdornment position="end"><Tooltip title="Max"><IconButton onClick={() => { setValue(max) }}><ArrowUpwardIcon sx={{ fontSize: "15px" }} /></IconButton></Tooltip></InputAdornment> : <></>}
								<InputAdornment position="end">{symbol}</InputAdornment>
							</>}
							size="small" margin="dense" fullWidth onChange={onChange} value={value}
						/>
					</> : <></>
				}
				<Stack>
					{showPercentageSlider && max ? <Box sx={{ padding: "0 20px", height: "20px" }}><Slider
						size="small"
						defaultValue={0}
						valueLabelDisplay="auto"
						valueLabelFormat={(v) => { return `${v}%` }}
						onChange={onSliderChange}
					/></Box> : <></>}
					<Stack direction="row" justifyContent="space-between">
						{symbol === native.symbol ? <FormHelperText component="div"><ShortDecimal currency="$" value={nativePrice * (isNaN(parseFloat(value)) ? 0 : parseFloat(value))} /></FormHelperText> : <div />}
						{max && showBalance ? <FormHelperText component="div"><ShortDecimal currency="Balance:&nbsp;" value={max} /></FormHelperText> : <div />}
					</Stack>
				</Stack>
			</FormControl>
		</>
	);
}